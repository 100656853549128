import * as React from 'react';
import {
  Box,
  Typography,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ReactQuillInput from 'components/react-quill/ReactQuill';
import { Clear } from '@mui/icons-material';
import DatePikerInput from 'components/input/DatePikerInput';
import { useNotice } from 'hooks/useNotice';
import { LoadingButton } from '@mui/lab';
import ImageUploader from 'components/common/ImageUploader';

const initialState = {
  title: '',
  date: '',
  content: '',
};
export default function AddNoticeModal({ open, handleClose }) {
  const { addNotice, isAdding } = useNotice();

  const [formInput, setFormInput] = React.useState(initialState);
  const [dOB, setdOB] = React.useState(new Date());
  const [reactQuill, setReactQuill] = React.useState('');
  const [noticeType, setNoticeType] = React.useState('central');
  const [image, setImage] = React.useState('');

  const handleInputForms = (event) => {
    const { name, value } = event.target;
    setFormInput({ ...formInput, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addNotice(
      {
        ...formInput,
        date: dOB,
        image,
        body: reactQuill,
        type: noticeType,
      },
      () => handleClose()
    );
  };

  const handleChange = (newdOB) => {
    setdOB(newdOB);
  };

  const handlerReactquill = (newContent) => {
    setReactQuill(newContent);
  };

  const onUploadImage = (result) => {
    setImage(result?.url);
  };

  return (
    <Modal open={open} onClose={handleClose} sx={{ overflowY: 'auto' }}>
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Clear onClick={handleClose} />
        </Box>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: 'center' }}
        >
          New Notice
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Notice for:</InputLabel>
            <Select
              value={noticeType}
              label="Notice type"
              onChange={(e) => setNoticeType(e.target.value)}
            >
              <MenuItem value={'central'}>Central</MenuItem>
              <MenuItem value={'hsc'}>HSC</MenuItem>
              <MenuItem value={'hons'}>Degree</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ marginTop: '1rem' }}>
            <TextField
              required
              helperText=" "
              name="title"
              onChange={handleInputForms}
              label="Notice Title"
              sx={{ width: { xs: '100%' } }}
            />
          </Box>
          <Box>
            <DatePikerInput
              label={'Date of Notice'}
              value={dOB}
              handleChange={handleChange}
              required={true}
            />
          </Box>
          <Box sx={{ marginTop: '1rem' }}>
            <ReactQuillInput
              value={reactQuill}
              handleChange={handlerReactquill}
            />
          </Box>
          <ImageUploader onUploadImage={onUploadImage} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <LoadingButton loading={isAdding} variant="contained" type="submit">
              Publish Notice
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
const style = {
  position: 'absolute',
  top: '58%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  maxWidth: '500px',
  minHeight: { xs: '300px', md: '400px', lg: '500px' },
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
  '@media (max-width: 600px)': {
    top: '65%',
  },
};
