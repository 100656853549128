import * as React from "react";
import { Stack, Box, TextField } from "@mui/material";
import DatePikerInput from "./DatePikerInput";

export default function DateRangePikerInput({
  start,
  end,
  handleStart,
  handleEnd,
  startLabel,
  endLabel,
  required,
}) {
  return (
    <>
      <Stack direction="row">
        <Box sx={{ marginTop: "1rem" }}>
          <DatePikerInput
            required={required}
            label={startLabel}
            value={start}
            handleChange={handleStart}
          />
        </Box>
        <Box
          sx={{
            marginTop: "1rem",
            padding: "0 0.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          -{" "}
        </Box>
        <Box sx={{ marginTop: "1rem" }}>
          <DatePikerInput
            required={required}
            label={endLabel}
            value={end}
            handleChange={handleEnd}
          />
        </Box>
      </Stack>
    </>
  );
}
