import { Tab, Tabs, Box, Typography } from '@mui/material';
import TabPanel from 'components/common/TabPanelCommon';
import { useHons } from 'hooks/useHons';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FacultyList from './FacultyList';
import HonsNotice from './HonsNotice';
import HonsOthers from './HonsOthers';

const HonsData = () => {
  const { getAllHons, data } = useHons();

  const [value, setValue] = React.useState(0);
  const handleChange = (_, v) => setValue(v);
  const { state } = useLocation();

  const onlyList = state.onlyList;

  useEffect(() => {
    getAllHons();
  }, []);

  console.log(data);

  return (
    <>
      <Typography variant="h6" textAlign={'center'}>
        {state.title}
      </Typography>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Faculty list" />
          {!onlyList && <Tab label="Notices" />}
          {!onlyList && <Tab label="Other info" />}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FacultyList
          objectKey={state.objectKey}
          list={data[state.objectKey]?.faculties}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HonsNotice objectKey={state.objectKey} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <HonsOthers objectKey={state.objectKey} data={data[state.objectKey]} />
      </TabPanel>
    </>
  );
};

export default HonsData;
