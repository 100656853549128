import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "@emotion/styled";
const ReactQuillInput = ({ value, handleChange }) => {
 

  return (
    <StyledQuill
      theme="snow"
      value={value}
      name="content"
      onChange={handleChange}
      formats={ReactQuillInput.formats}
      modules={ReactQuillInput.modules}
    />
  );
};

export default ReactQuillInput;

const StyledQuill = styled(ReactQuill)`
  // .ql-toolbar.ql-snow:nth-of-type(1) {
  //   /// add if  React strict mode if not delete
  // }
  .ql-editor {
    height: 250px;
  }
`;
/*
 * Quill modules to attach to editor
 * See http://quilljs.com/docs/modules/ for complete options
 */

ReactQuillInput.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ReactQuillInput.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
