import * as React from "react";
import { Stack, Box, colors } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Delete, Edit, OpenWith } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function createData(name, date) {
  return { name, date };
}

const rows = [
  createData("Dr. Mobin Uddin Ahmed 1", "12 jul, 2022 to 15 jul, 2022"),
  createData("Dr. Mobin Uddin Ahmed 2", "12 jul, 2022 to 15 jul, 2022"),
  createData("Dr. Mobin Uddin Ahmed 3", "12 jul, 2022 to 15 jul, 2022"),
  createData("Dr. Mobin Uddin Ahmed 4", "12 jul, 2022 to 15 jul, 2022"),
  createData("Dr. Mobin Uddin Ahmed 5", "12 jul, 2022 to 15 jul, 2022"),
];

export default function TeachersListTableDnd() {
  const [data, setData] = React.useState(rows);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const handleDragEnd = (result) => {
    console.log(result);
    setData(reorder(data, result.source.index, result.destination.index));
    console.log(data);
  };
  React.useEffect(() => {
    console.log(data);
  });
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 500,
          width: { xs: "100%", lg: "80%", xl: "70%" },
          border: "1px solid #e0e0e0",
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "auto" }}>SL</TableCell>
            <TableCell sx={{ width: "40%" }}>Name</TableCell>
            <TableCell sx={{ width: "40%" }} align="left">
              Date
            </TableCell>
            <TableCell sx={{ width: "40%" }} align="left">
              Action
            </TableCell>
            <TableCell sx={{ width: "40%" }} align="left">
              Move
            </TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext
          onDragEnd={handleDragEnd}
          style={{ overflowY: "unset" }}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <TableBody
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{ overflowY: "unset" }}
              >
                {data.map((row, index) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <TableRow
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        elevation={2}
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{ width: "auto" }}
                          component="th"
                          scope="row"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{ width: "40%" }}
                          component="th"
                          scope="row"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ width: "40%" }} align="left">
                          {row.date}
                        </TableCell>
                        <TableCell sx={{ width: "40%" }} align="left">
                          <Stack
                            direction="row"
                            justifyContent="Start"
                            alignItems="center"
                            sx={{ p: 1 }}
                          >
                            {" "}
                            <Edit
                              size="small"
                              sx={{
                                borderRadius: "50%",
                                p: 1,
                                mr: 1,
                                color: colors.common.white,
                                bgcolor: colors.green[500],
                                cursor: "pointer",
                              }}
                            />{" "}
                            <Delete
                              size="small"
                              sx={{
                                borderRadius: "50%",
                                p: 1,
                                color: colors.common.white,
                                bgcolor: colors.red[500],
                                cursor: "pointer",
                              }}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ width: "40%" }} align="left">
                          <OpenWith
                            size="small"
                            sx={{
                              p: 1,
                              mr: 1,
                              color: colors.common.black,
                              cursor: "all-scroll",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
}
