import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Stack, Grid, TextareaAutosize } from '@mui/material';
import Divider from '@mui/material/Divider';
import FileSelector from 'components/common/FileSelector';
import { LoadingButton } from '@mui/lab';
import { useHSC } from 'hooks/useHSC';

const HSCData = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { isAdding, addHSC } = useHSC();

  const [curriculum, setCurriculum] = useState('');
  const [mentor, setMentor] = useState('');
  const [routine, setRoutine] = useState('');
  const [result, setResult] = useState('');
  const [prevQues, setPrevQues] = useState('');
  const [books, setBooks] = useState('');

  const onSubmitData = () => {
    const formData = new FormData();
    formData.append('key', state.objectKey);
    formData.append('curriculum', curriculum || '');
    formData.append('mentor', mentor || '');
    formData.append('routine', routine || '');
    formData.append('result', result || '');
    formData.append('prevQues', prevQues || '');
    formData.append('books', books || '');

    addHSC(formData, () => {
      navigate(-1);
    });
  };

  React.useEffect(() => {
    if (state.curriculum) setCurriculum(state.curriculum);
    if (state.mentor) setMentor(state.mentor);
    if (state.routine) setRoutine(state.routine);
    if (state.result) setResult(state.result);
    if (state.prevQues) setPrevQues(state.prevQues);
    if (state.books) setBooks(state.books);
  }, [state]);

  if (!state) return null;

  return (
    <Stack spacing={2}>
      <Typography variant="h6" textAlign={'center'}>
        {state.title}
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FileSelector
            label={'পাঠ্যক্রম '}
            file={curriculum}
            setFile={setCurriculum}
          />
        </Grid>
        <Grid item xs={6}>
          <FileSelector label={'মেন্টর'} file={mentor} setFile={setMentor} />
        </Grid>
        <Grid item xs={6}>
          <FileSelector
            label={'ক্লাস রুটিন'}
            file={routine}
            setFile={setRoutine}
          />
        </Grid>
        <Grid item xs={6}>
          <FileSelector label={'ফলাফল '} file={result} setFile={setResult} />
        </Grid>
        <Grid item xs={6}>
          <FileSelector
            label={'বিগত প্রশ্নপত্র'}
            file={prevQues}
            setFile={setPrevQues}
          />
        </Grid>
      </Grid>
      <Stack spacing={2} px={2}>
        <Typography component="label">পাঠ্যপুস্তক - Enter Text</Typography>
        <TextareaAutosize
          value={books}
          placeholder={`Bangla,https://www.test.com/bd.pdf\nEnglish,https://www.test.com/tet.pdf`}
          style={{ minHeight: 200, fontSize: 20, padding: 10 }}
          onChange={(e) => setBooks(e.target.value)}
        />
        <LoadingButton
          loading={isAdding}
          onClick={onSubmitData}
          variant="contained"
        >
          Update Information
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default HSCData;
