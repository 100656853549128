import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import FileSelector from 'components/common/FileSelector';
import routes from 'data/routes';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useClubs } from '../../../hooks/useClubs';
import ClubCommittee from './ClubCommittee';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { toast } from 'react-toastify';

const ClubEdit = () => {
  const { singleClub, getClubById, loading, updateClub } = useClubs();
  const { id } = useParams();

  console.log({ singleClub });

  const [name, setName] = useState('');
  const [slogan, setSlogan] = useState('');
  const [history, setHistory] = useState('');
  const [icon, setIcon] = useState('');
  const [cover, setCover] = useState('');

  const [committee, setCommittee] = useState([]);
  const [achievements, setAchievements] = useState('');
  console.log({ icon });

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!name || !slogan || !history || !achievements) {
      return toast.error('All fields are required');
    }

    console.log(committee);

    const formData = new FormData();
    formData.append('id', id);
    formData.append('committee', JSON.stringify(committee));
    formData.append('achievements', achievements);
    formData.append('name', name);
    formData.append('slogan', slogan);
    formData.append('history', history);
    formData.append('icon', icon);
    formData.append('cover', cover);

    updateClub(formData, () => {
      setName('');
      setSlogan('');
      setHistory('');
      setCommittee([]);
      setAchievements('');
      setIcon('');
      setIcon('');
      setCover('');
    });
  };

  useEffect(() => {
    getClubById(id);
  }, [id]);

  useEffect(() => {
    if (singleClub) {
      setName(singleClub.name);
      setSlogan(singleClub.slogan);
      setHistory(singleClub.history);
      setCommittee(singleClub.committee);
      setAchievements(singleClub.achievements);
      setIcon(singleClub.icon);
      setCover(singleClub.cover);
    }
  }, [singleClub]);

  if (loading) return <LoadingSpinner />;

  return (
    <Box sx={{ width: '100%', margin: '0 auto', paddingBottom: 20 }}>
      <Typography textAlign="center" mt={2}>
        Add new Club
      </Typography>
      <Typography textAlign="center" mt={2}>
        <Link to={routes.COLLEGE}>Back to all clubs</Link>
      </Typography>
      <Stack spacing={1} mt={2} component="form" onSubmit={handleUpdate}>
        <TextField
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Club name"
          fullWidth
        />
        <FileSelector
          file={icon}
          setFile={setIcon}
          label="Club Logo"
          onlyImage
        />
        <FileSelector file={cover} setFile={setCover} label="Cover photo" />
        <TextField
          name="slogan"
          value={slogan}
          onChange={(e) => setSlogan(e.target.value)}
          label="slogan"
          fullWidth
        />
        <TextField
          name="history"
          value={history}
          onChange={(e) => setHistory(e.target.value)}
          label="History"
          fullWidth
          rows={5}
          multiline
        />
        <FileSelector
          file={achievements}
          setFile={setAchievements}
          label="Achievement"
        />
        <ClubCommittee committee={committee} setCommittee={setCommittee} />
        <LoadingButton loading={false} variant="contained" type="submit">
          Submit to create CLub
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ClubEdit;
