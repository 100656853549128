import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Modal,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useCommon } from 'hooks/useCommon';
import React from 'react';
import { config } from 'utils/config';
import EditCommon from './EditCommon';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { colors, Alert } from '@mui/material';

const PreviewCommon = (props) => {
  const {
    showTextArea = false,
    showImages = false,
    showBody = false,
    itemKey,
    warning,
  } = props;

  const [showModal, setShowModal] = React.useState(false);

  const { items } = useCommon();

  console.log(items);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <Stack sx={{ my: 4 }} alignItems="center">
      <Button onClick={openModal} variant="contained" sx={{ mb: 3 }}>
        Edit Content
      </Button>
      {warning && <Alert severity="warning">{warning}</Alert>}
      <Card sx={{ m: 2, p: 1, minWidth: '50%' }}>
        {showTextArea && (
          <TextareaAutosize
            disabled
            value={items[itemKey]?.textArea}
            style={{ minHeight: 200, fontSize: 20, width: '90vw' }}
          />
        )}
        {showBody && (
          <CardContent sx={{ border: '1px dashed gray', my: 2 }}>
            <Typography variant="body2" color="text.secondary">
              {items[itemKey]?.body}
            </Typography>
          </CardContent>
        )}
        {showImages &&
          items[itemKey]?.files?.map((file, idx) => {
            if (file?.url?.includes('.pdf')) {
              return (
                <Stack
                  key={idx}
                  direction="row"
                  alignItems="center"
                  sx={{
                    border: '1px solid #ddd',
                    padding: 2,
                    mt: 2,
                  }}
                >
                  <PictureAsPdfIcon sx={{ color: colors.red[500] }} />
                  <Typography sx={{ marginLeft: 2, marginRight: 5 }}>
                    {file.caption}
                  </Typography>
                  <Button sx={{ marginLeft: 'auto' }}>
                    <a
                      href={config.fileUrl + file.url}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      Download
                    </a>
                  </Button>
                </Stack>
              );
            }

            return (
              <>
                <CardMedia
                  key={idx}
                  component="img"
                  image={config.fileUrl + file.url}
                  alt={file.caption || 'Not found'}
                />{' '}
                <Typography sx={{ margin: 2, textAlign: 'center' }}>
                  {file.caption}
                </Typography>
              </>
            );
          })}
      </Card>
      <Modal open={showModal} onClose={closeModal} sx={{ overflowY: 'auto' }}>
        <Box sx={style}>
          <EditCommon
            onFinish={closeModal}
            data={items[itemKey]}
            itemKey={itemKey}
            showImages={showImages}
            showBody={showBody}
            showTextArea={showTextArea}
          />
        </Box>
      </Modal>
    </Stack>
  );
};

export default PreviewCommon;

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  maxWidth: '500px',
  minHeight: { xs: '300px', md: '400px', lg: '500px' },
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
  '@media (max-width: 600px)': {
    top: '65%',
  },
};
