import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const usePendingStudents = create((set, get) => ({
  isLoading: false,
  data: null,
  isApproving: false,

  getPendingStudents: async (page = 1) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get(
        `admin/pendingStudents?page=${page}&limit=5`
      );
      set(() => ({ isLoading: false, data: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  approveAStudent: async (id) => {
    set(() => ({ isApproving: true }));
    try {
      await apiClient.post(`admin/approveStudent`, { id });
      set(() => ({ isApproving: false }));
      toast.success('Student approved');
      get().getPendingStudents();
    } catch (error) {
    } finally {
      set(() => ({ isApproving: false }));
    }
  },
}));
