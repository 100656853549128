import React from "react";
import {
  Stack,
  Grid,
  Typography,
  Box,
  colors,
  IconButton,
} from "@mui/material";

import MultipleImagePreviewCard from "components/cards/MultipleImagePreviewCard";
import { CloudUpload, Add } from "@mui/icons-material";

const ContentInfo = ({ selectedImage, removeSelectedImage }) => {
  return (
    <>
      <Grid container spacing={2}>
        {selectedImage && (
          <MultipleImagePreviewCard
            selectedImage={selectedImage}
            removeSelectedImage={removeSelectedImage}
          />
        )}
      </Grid>
    </>
  );
};

export default ContentInfo;
