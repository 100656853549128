import {
  Home,
  AccountBalance,
  Slideshow,
  Topic,
  Settings,
  DirectionsCar,
} from '@mui/icons-material';
import routes from './routes';
import SecurityIcon from '@mui/icons-material/Security';

const menuItems = [
  {
    id: '1',
    title: 'Dashboard',
    route: routes.DASHBOARD,
    icon: <Home />,
  },
  {
    id: '22',
    title: 'Admins',
    route: routes.ADMIN_LIST,
    icon: <SecurityIcon />,
  },
  {
    id: '2',
    title: 'কলেজ',
    route: routes.COLLEGE,
    icon: <Slideshow />,
  },
  {
    id: '6',
    title: `টিচার্স কার & স্টুডেন্ট বাস `,
    route: routes.BUS_CAR,
    icon: <DirectionsCar />,
  },
  {
    id: '7',
    title: `স্পোর্টস কর্ণার`,
    route: routes.SPORT_CORNER,
    icon: <DirectionsCar />,
  },
  {
    id: '9',
    title: 'স্কিল ডেভেলপমেন্ট',
    route: routes.SKILL_DEVELOPMENT,
    icon: <Settings />,
  },
  {
    id: '11',
    title: 'স্টুডেন্ট ব্যাংকিং',
    route: routes.STUDENT_BANKING,
    icon: <AccountBalance />,
  },
  {
    id: '10',
    title: 'স্টুডেন্ট ডেটাবেজ',
    route: routes.STUDENT_DATABASE,
    icon: <Topic />,
  },
];

export default menuItems;
