import React from "react";
import PropTypes from "prop-types";
import {
  Stack,
  Typography,
  Button,
  Grid,
  Box,
  Modal,
  Tabs,
  Tab,
  theme,
} from "@mui/material";

const TabItem = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: 3 }}>{children}</Box>}
    </div>
  );
};

export default TabItem;
TabItem.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
