import * as React from 'react';
import {
  Button,
  Box,
  Typography,
  Modal,
  CardActions,
  Stack,
  Grid,
  TextField,
  Avatar,
} from '@mui/material';
import { useEffect } from 'react';
import { useStudent } from 'hooks/useStudent';
import { LoadingButton } from '@mui/lab';
import { useAuth } from 'hooks/useAuth';

const fields = [
  'age',
  'dob',
  'electiveSubjects',
  'fatherName',
  'fathersPhone',
  'fourthSubjects',
  'group',
  'homeDistrict',
  'legalGuardian',
  'mainSubjects',
  'motherName',
  'mothersPhone',
  'fullName',
  'nationality',
  'permanentAddress',
  'presentAddress',
  'quota',
  'religion',
  'sscBoard',
  'sscGpa',
  'sscGroup',
  'sscRegNo',
  'sscRollNo',
  'sscYear',
  'facebook',
  'email',
  'studentId',
  'phone',
  'version',
];

export default function ViewStudentDetailsModal({ open, handleClose, data }) {
  const [formData, setFormData] = React.useState({});

  const { role } = useAuth();

  const isReadOnly = role === 'officeAdmin';

  const { updateStudent, isAdding, removeStudent } = useStudent();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRemove = () => {
    if (window.confirm('Are you sure to remove?')) {
      removeStudent(formData._id);
    }
  };
  const handleEdit = () => {
    updateStudent({ ...formData });
  };

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ overflowY: 'auto', zIndex: '10' }}
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: 'center' }}
        >
          Student Details
        </Typography>

        <Stack
          direction="row"
          justifyContent="center"
          my={2}
          alignItems="center"
        >
          <CardActions>
            <LoadingButton
              loading={isAdding}
              onClick={handleEdit}
              variant="contained"
              color="primary"
              disabled={isReadOnly}
            >
              Update Information
            </LoadingButton>
            <LoadingButton
              loading={isAdding}
              onClick={handleRemove}
              variant="contained"
              disabled={isReadOnly}
              color="error"
            >
              Delete Student
            </LoadingButton>
          </CardActions>
        </Stack>

        <Grid container spacing={2}>
          {fields.map((item) => (
            <CommonInput
              key={item}
              name={item}
              value={formData[item]}
              onChange={handleInputChange}
            />
          ))}
        </Grid>

        <Stack sx={{ mt: 5 }} alignItems="center">
          {formData.photo && (
            <img
              style={{
                height: 100,
                width: 100,
                borderRadius: 50,
                objectFit: 'contain',
              }}
              alt={formData.name}
              src={formData.photo}
            />
          )}
        </Stack>
      </Box>
    </Modal>
  );
}

const CommonInput = ({ name, value, onChange }) => {
  return (
    <Grid item xs={12} lg={6}>
      <TextField
        placeholder={name}
        label={name}
        name={name}
        multiline
        fullWidth
        maxRows={6}
        value={value}
        onChange={onChange}
      />
    </Grid>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -20%)',
  width: '75vw',
  maxWidth: '1000px',
  minHeight: { xs: '300px', md: '400px', lg: '500px' },
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: { xs: 2, md: 3 },
};
