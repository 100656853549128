import { Container } from '@mui/material';
import routes from 'data/routes';
import { useAuth } from 'hooks/useAuth';
// import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const GuestLayout = () => {
  const { isLoggedIn, role } = useAuth();

  if (isLoggedIn && role === 'admin') {
    return <Navigate to={routes.DASHBOARD} />;
  }
  if (isLoggedIn && role === 'deptAdmin') {
    return <Navigate to={routes.DEPT_ADMIN_HOME} />;
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: '100vh',
        padding: 2.5,
      }}
    >
      <Outlet />
    </Container>
  );
};

export default GuestLayout;
