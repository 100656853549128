// import { LoadingSpinner } from 'components';
import routes from 'data/routes';
import AdminLayout from 'layouts/AdminLayout';
import GuestLayout from 'layouts/GuestLayout';
import { DashboardPage, FourOhFour, LoginPage } from 'pages';
import HomeSliders from 'pages/admin-pages/HomeSliders';
import Notice from 'pages/admin-pages/Notice';
import DepartmentInfo from 'pages/admin-pages/department-info/DepartmentInfo';
import Library from 'pages/admin-pages/Library';
import SkillDevelopment from 'pages/admin-pages/SkillDevelopment';
import StudentDatabase from 'pages/admin-pages/student-db';
import StudentBanking from 'pages/admin-pages/StudentBanking';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Divitions from 'pages/admin-pages/department-pages/Divitions';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { getValue } from 'utils/storage';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import LandingPage from 'pages/guest-pages/LandingPage';
import BongobondhuPage from 'pages/admin-pages/Bongobondhu';
import BusCar from 'pages/admin-pages/BusCar';
import CitizenCharter from 'pages/admin-pages/CitizenCharter';
import Publications from 'pages/admin-pages/Publications';
import CollegeHome from 'pages/admin-pages/college';
import SportsCornerPage from 'pages/admin-pages/SportsCornerPage';
import InfoRight from '../pages/admin-pages/InfoRight';
import OnlineAdmission from '../pages/admin-pages/OnlineAdmission';
import HSCData from 'pages/admin-pages/department-info/HSCData';
import HonsData from 'pages/admin-pages/department-info/HonsData';
import AdminList from 'pages/admin-pages/admin-list';
import DeptAdminLayout from 'layouts/DeptAdminLayout';
import ClubAdd from 'pages/admin-pages/college/ClubAdd';
import ClubEdit from 'pages/admin-pages/college/ClubEdit';
import Administrative from 'pages/admin-pages/Administrative';

const AppRouter = () => {
  const { setLoggedIn } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  const getAuth = async () => {
    const hasToken = getValue('token');
    if (hasToken) {
      setLoggedIn();
      setLoaded(true);
    } else {
      setLoaded(true);
      navigate('/login');
    }
  };

  useEffect(() => {
    getAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      <Route path={routes.LANDING} element={<LandingPage />} />

      {/* Guest Routes */}
      <Route element={<GuestLayout />}>
        <Route path={routes.LOGIN} element={<LoginPage />} />
      </Route>

      {/* Admin Routes */}
      <Route element={<AdminLayout />}>
        <Route path={routes.DASHBOARD} element={<DashboardPage />} />
        <Route path={routes.ADMIN_LIST} element={<AdminList />} />
        <Route path={routes.HOME_SLIDERS} element={<HomeSliders />} />
        <Route path={routes.NOTICE} element={<Notice />} />
        <Route path={routes.BONGOBONDHU} element={<BongobondhuPage />} />
        <Route path={routes.DEPARTMENT_INFO} element={<DepartmentInfo />} />
        <Route path={routes.SCIENCE_DIVISION} element={<Divitions />} />
        <Route path={routes.LIBRARY} element={<Library />} />
        <Route path={routes.BUS_CAR} element={<BusCar />} />
        <Route path={routes.SKILL_DEVELOPMENT} element={<SkillDevelopment />} />
        <Route path={routes.STUDENT_DATABASE} element={<StudentDatabase />} />
        <Route path={routes.STUDENT_BANKING} element={<StudentBanking />} />
        <Route path={routes.CITIZEN_CHARTER} element={<CitizenCharter />} />
        <Route path={routes.PUBLICATIONS} element={<Publications />} />
        <Route path={routes.COLLEGE} element={<CollegeHome />} />
        <Route path={routes.SPORT_CORNER} element={<SportsCornerPage />} />
        <Route path={routes.INFO_RIGHT} element={<InfoRight />} />
        <Route path={routes.ONLINE_ADMISSION} element={<OnlineAdmission />} />
        <Route path={routes.HSC_DATA} element={<HSCData />} />
        <Route path={routes.HONS_DATA} element={<HonsData />} />
        <Route path={routes.CLUB_ADD} element={<ClubAdd />} />
        <Route path={routes.CLUB_EDIT + `/:id`} element={<ClubEdit />} />
        <Route path={routes.ADMINISTRATIVE} element={<Administrative />} />
      </Route>

      {/* DeptAdmin Routes */}

      <Route path={routes.DEPT_ADMIN} element={<DeptAdminLayout />}>
        <Route path={routes.DEPT_ADMIN_HOME} element={<DepartmentInfo />} />
        <Route path={routes.DEPT_ADMIN_HSC_DATA} element={<HSCData />} />
        <Route path={routes.DEPT_ADMIN_HONS_DATA} element={<HonsData />} />
        <Route path={routes.DEPT_ADMIN_ADMINIS} element={<Administrative />} />
        <Route path={routes.DEPT_ADMIN_SPORTS} element={<SportsCornerPage />} />
        <Route path={routes.DEPT_ADMIN_SPORTS} element={<SportsCornerPage />} />
        <Route path={routes.DEPT_ADMIN_COLLEGE} element={<CollegeHome />} />
        <Route path={routes.DEPT_ADMIN_COLLEGE} element={<CollegeHome />} />
        <Route path={routes.DEPT_ADMIN_LIBRARY} element={<Library />} />{' '}
        <Route path={routes.DEPT_ADMIN_CLUB_ADD} element={<ClubAdd />} />
        <Route
          path={routes.DEPT_ADMIN_CLUB_EDIT + `/:id`}
          element={<ClubEdit />}
        />
        <Route
          path={routes.DEPT_ADMIN_STUDENT_DB}
          element={<StudentDatabase />}
        />
      </Route>

      {/* Common Routes */}
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  );
};

export default AppRouter;
