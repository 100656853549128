import React from 'react';
import { Stack, Button, Alert } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Info, Print } from '@mui/icons-material';
import ViewStudentDetailsModal from './StudentDetailsModal';
import PdfReader from './PdfReader';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { usePendingStudents } from 'hooks/usePendingStudent';
import { LoadingButton } from '@mui/lab';

export default function StudentListTable({ students, type = 'normal' }) {
  const [open, setOpen] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [showPrint, setShowPrint] = React.useState(false);

  const { approveAStudent, isApproving } = usePendingStudents();

  const handleEdit = (value) => {
    if (value === false) {
      setEdit(value);
    } else {
      setEdit(!edit);
    }
  };

  const handleDetailOpen = (newData) => {
    setOpen(true);
    setSelectedData(newData);
  };

  const handleDetailClose = () => {
    setOpen(false);
    setEdit(false);
  };

  const handlePrintOpen = (newData) => {
    setShowPrint(true);
    setSelectedData(newData);
  };

  const handlePrintClose = () => {
    setShowPrint(false);
  };

  const onApprove = (id) => {
    approveAStudent(id);
  };

  if (students?.length === 0) {
    return <Alert severity="error">No students on this list!</Alert>;
  }

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 500,
          width: { xs: '100%', lg: '100%' },
          border: '1px solid #e0e0e0',
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 'auto' }}>SL</TableCell>
            <TableCell sx={{ width: '40%' }}>Student ID</TableCell>
            <TableCell sx={{ width: '40%' }}>Name</TableCell>
            <TableCell sx={{ width: '40%' }} align="left">
              Date of Birth
            </TableCell>
            <TableCell sx={{ width: '40%' }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ width: 'auto' }} scope="row">
                {index + 1}
              </TableCell>
              <TableCell sx={{ width: '40%' }} scope="row">
                {student.studentId}
              </TableCell>
              <TableCell sx={{ width: '40%' }} scope="row">
                {student.fullName}
              </TableCell>
              <TableCell sx={{ width: '40%' }} align="left">
                {student.dob}
              </TableCell>
              <TableCell sx={{ width: '40%' }} align="left">
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  sx={{ p: 1 }}
                >
                  <Button
                    onClick={() => handleDetailOpen(student)}
                    variant="contained"
                    startIcon={<Info />}
                  >
                    Details
                  </Button>
                  {type === 'pending' ? (
                    <LoadingButton
                      onClick={() => onApprove(student._id)}
                      variant="contained"
                      color="success"
                      loading={isApproving}
                      startIcon={<CheckCircleOutlineIcon />}
                    >
                      Approve
                    </LoadingButton>
                  ) : (
                    <Button
                      onClick={() => handlePrintOpen(student)}
                      variant="contained"
                      color="secondary"
                      startIcon={<Print />}
                    >
                      Print
                    </Button>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ViewStudentDetailsModal
        open={open}
        handleClose={handleDetailClose}
        data={selectedData}
      />
      <PdfReader
        error={Object.keys(selectedData).length > 0 ? false : true}
        dataset={selectedData}
        open={showPrint}
        handleClose={handlePrintClose}
      />
    </TableContainer>
  );
}
