import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useCommon } from 'hooks/useCommon';
import TabPanel from 'components/common/TabPanelCommon';
import PreviewCommon from 'components/common/PreviewCommon';
import { Alert, Typography } from '@mui/material';

const BongobondhuPage = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_, v) => setValue(v);

  const { items } = useCommon();
  console.log({ items });

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="কর্মকর্তা ও আপীল" />
            <Tab label="আবেদনপত্র" />
            <Tab label="স্বপ্রনোদিত" />
            <Tab label="আইন/বিধি" />
          </Tabs>
        </Box>
        <Alert severity="error" sx={{ m: 5 }}>
          এড/এডিট করার সময় অবশ্যই ক্যাপশন দিবেন
        </Alert>
        <TabPanel value={value} index={0}>
          <PreviewCommon itemKey={'infoRight1'} showBody showImages />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PreviewCommon itemKey={'infoRight2'} showBody showImages />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PreviewCommon itemKey={'infoRight3'} showBody showImages />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PreviewCommon itemKey={'infoRight4'} showBody showImages />
        </TabPanel>
      </Box>
    </div>
  );
};

export default BongobondhuPage;
