import React from 'react';
import { Button, Typography, colors } from '@mui/material';
import { AddPhotoAlternate } from '@mui/icons-material';

const ImageFileInput = ({ imageChange, imageref }) => {
  return (
    <Button
      variant="contained"
      component="label"
      sx={{
        mt: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem 0',
        bgcolor: colors.common.white,
        color: colors.blue[500],
        '&:hover': {
          color: 'white',
        },
        border: '1px dashed ' + colors.blue[500],
      }}
    >
      <AddPhotoAlternate />
      <Typography
        variant="body1"
        sx={{ padding: '0.5rem ', fontWeight: '600' }}
      >
        Select File
      </Typography>
      <input
        ref={imageref}
        accept="image/*"
        type="file"
        onChange={imageChange}
        hidden
      />
    </Button>
  );
};

export default ImageFileInput;
