import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useStudent = create((set, get) => ({
  isLoading: false,
  isAdding: false,
  data: null,
  searching: false,
  searchedData: null,

  addStudent: async (formData, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.post('/admin/student', formData);
      toast.success('student added');
      set(() => ({ isAdding: false }));
      get().getAllStudents();
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  updateStudent: async (formData, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.put('/admin/student', formData);
      toast.success('students uploaded');
      set(() => ({ isAdding: false }));
      get().getAllStudents();
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  removeStudent: async (id) => {
    try {
      await apiClient.delete(`/admin/student/${id}`);
      toast.success('Student removed');
      get().getAllStudents();
    } catch (error) {
      console.log({ error });
    }
  },

  getAllStudents: async (page) => {
    set(() => ({ isLoading: true, searchedData: null }));
    try {
      const response = await apiClient.get(
        `admin/student?page=${page}&limit=5`
      );
      set(() => ({ isLoading: false, data: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  searchStudents: async (query) => {
    set(() => ({ searching: true }));
    try {
      const response = await apiClient.get(`admin/searchStudent/${query}`);
      set(() => ({ searching: false, searchedData: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ searching: false }));
    }
  },

  clearSearch: async () => {
    set(() => ({ searchedData: null }));
  },
}));
