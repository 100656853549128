import React, { useState } from 'react';
import { Stack, IconButton, Paper, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import { useStudent } from 'hooks/useStudent';

const SearchStudents = () => {
  const { searching, searchedData, searchStudents } = useStudent();

  console.log(searchedData);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    if (!searchQuery) return toast.error('Empty field');
    searchStudents(searchQuery);
  };

  return (
    <Stack direction="row" sx={{ mt: 3, width: '100%' }}>
      <Paper
        component="div"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <InputBase
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search by student name or ID"
          inputProps={{ 'aria-label': 'search by student id or name' }}
        />
        <IconButton
          disabled={searching}
          onClick={handleSearch}
          type="button"
          sx={{ p: '10px' }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </Stack>
  );
};

export default SearchStudents;
