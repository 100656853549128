import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
// import { useAuth } from 'hooks/useAuth';
import { blueGrey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import Logout from '@mui/icons-material/Logout';
import { useAuth } from 'hooks/useAuth';

const TopBar = ({ onOpen }) => {
  const { logoutUser } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onLogout() {
    logoutUser(navigate);
  }

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 10 }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            onClick={onOpen}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={handleMenu}
            color="inherit"
            sx={{ marginLeft: 'auto' }}
          >
            <Avatar sx={{ bgcolor: blueGrey[400] }}>CV</Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={onLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
