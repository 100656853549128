import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useVersion = create((set, get) => ({
  isLoading: false,
  data: null,
  updating: false,

  getVersion: async () => {
    set(() => ({ isLoading: true }));
    try {
      const res = await apiClient.get('/admin/app-version');

      if (!res.data.data) {
        await apiClient.get('/admin/app-version/init');
        const res = await apiClient.get('/admin/app-version');
        set(() => ({ isLoading: false, data: res.data.data }));
      } else {
        set(() => ({ isLoading: false, data: res.data.data }));
      }
    } catch (error) {
      set(() => ({ isLoading: false }));
    }
  },

  updateVersion: async () => {
    set(() => ({ updating: true }));
    try {
      await apiClient.put('/admin/app-version');
      set(() => ({ updating: false }));
      get().getVersion();
    } catch (error) {
      set(() => ({ updating: false }));
    }
  },
}));
