import * as React from 'react';
import { Button, colors, IconButton, Input } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Delete } from '@mui/icons-material';
import { Stack } from '@mui/system';
import ImageUploader from 'components/common/ImageUploader';
import { useHons } from 'hooks/useHons';

const defaultValues = {
  name: 'NAME',
  designation: 'DESIGNATION',
  photo: '',
  phone: '000000000',
  email: 'test@test.com',
};

export default function FacultyList({ objectKey, list = [] }) {
  const [data, setData] = React.useState(list);

  const { addFaculties } = useHons();
  const handleChange = (newValue, id, key) => {
    const newData = data.map((single) => {
      if (single.id === id) {
        return { ...single, [key]: newValue };
      }
      return single;
    });
    setData(newData);
  };

  const onRemoveImage = (id) => {
    const newData = data.map((item) => {
      if (item.id !== id) return item;
      return { ...item, photo: '' };
    });
    setData(newData);
  };

  const onAddNew = () => {
    setData((prev) => [
      ...prev,
      { ...defaultValues, serial: prev.length + 1, id: prev.length + 1 },
    ]);
  };

  const onOrder = () => {
    const newData = data.sort((a, b) => a.serial - b.serial);
    setData([...newData]);
  };

  const onRemove = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData([...newData]);
  };

  const onSave = () => {
    addFaculties({ key: objectKey, faculties: data });
  };

  React.useEffect(() => {
    onOrder();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ p: 3 }}>
      <Stack py={2} direction="row" spacing={2}>
        <Button variant="contained" onClick={onAddNew}>
          Add New One
        </Button>
        <Button onClick={onOrder} variant="outlined" color="secondary">
          Reorder
        </Button>
        <Button onClick={onSave} variant="contained" color="error">
          Save
        </Button>
      </Stack>
      <Table
        sx={{
          minWidth: 800,
          width: { xs: '100%', lg: '90%', xl: '70%' },
          border: '1px solid #e0e0e0',
          mr: 2,
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Serial No.</TableCell>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Designation</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Photo</TableCell>
            <TableCell align="center">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflowY: 'unset' }}>
          {data.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell align="left" sx={{ width: '80px' }}>
                <Input
                  type="number"
                  value={row.serial}
                  onChange={(e) =>
                    handleChange(parseInt(e.target.value), row.id, 'serial')
                  }
                />
              </TableCell>
              <TableCell align="left" sx={{ width: '80px' }}>
                <Input
                  value={row.id}
                  onChange={(e) => handleChange(e.target.value, row.id, 'id')}
                />
              </TableCell>
              <TableCell align="left" sx={{ width: 'auto' }}>
                <Input
                  value={row.name}
                  onChange={(e) => handleChange(e.target.value, row.id, 'name')}
                />
              </TableCell>
              <TableCell align="left" sx={{ width: 'auto' }}>
                <Input
                  value={row.designation}
                  onChange={(e) =>
                    handleChange(e.target.value, row.id, 'designation')
                  }
                />
              </TableCell>
              <TableCell align="left" sx={{ width: 'auto' }}>
                <Input
                  value={row.phone}
                  onChange={(e) =>
                    handleChange(e.target.value, row.id, 'phone')
                  }
                />
              </TableCell>
              <TableCell align="left" sx={{ width: 'auto' }}>
                <Input
                  value={row.email}
                  onChange={(e) =>
                    handleChange(e.target.value, row.id, 'email')
                  }
                />
              </TableCell>
              <TableCell align="left">
                <ImageUploader
                  onUploadImage={(file) =>
                    handleChange(file.url, row.id, 'photo')
                  }
                  imageUrl={row.photo}
                  setImageUrl={() => onRemoveImage(row.id)}
                />
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={() => onRemove(row.id)}>
                  <Delete size="small" sx={{ color: 'red' }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
