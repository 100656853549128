import * as React from "react";
import { Stack, Box, colors } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Delete, Edit, OpenWith } from "@mui/icons-material";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Dr. Mobin Uddin Ahmed", "12 jul, 2022 to 15 jul, 2022"),
  createData("Dr. Mobin Uddin Ahmed", "12 jul, 2022 to 15 jul, 2022"),
  createData("Dr. Mobin Uddin Ahmed", "12 jul, 2022 to 15 jul, 2022"),
  createData("Dr. Mobin Uddin Ahmed", "12 jul, 2022 to 15 jul, 2022"),
  createData("Dr. Mobin Uddin Ahmed", "12 jul, 2022 to 15 jul, 2022"),
];

export default function TeachersList() {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 500,
          width: { xs: "100%", lg: "80%", xl: "70%" },
          border: "1px solid #e0e0e0",
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "40%" }}>Name</TableCell>
            <TableCell sx={{ width: "40%" }} align="left">
              Date
            </TableCell>
            <TableCell sx={{ width: "40%" }} align="left">
              Action
            </TableCell>
            <TableCell sx={{ width: "40%" }} align="left">
              Move
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ width: "40%" }} component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell sx={{ width: "40%" }} align="left">
                {row.calories}
              </TableCell>
              <TableCell sx={{ width: "40%" }} align="left">
                <Stack
                  direction="row"
                  justifyContent="Start"
                  alignItems="center"
                  sx={{ p: 1 }}
                >
                  {" "}
                  <Edit
                    size="small"
                    sx={{
                      borderRadius: "50%",
                      p: 1,
                      mr: 1,
                      color: colors.common.white,
                      bgcolor: colors.green[500],
                    }}
                  />{" "}
                  <Delete
                    size="small"
                    sx={{
                      borderRadius: "50%",
                      p: 1,
                      color: colors.common.white,
                      bgcolor: colors.red[500],
                    }}
                  />
                </Stack>
              </TableCell>
              <TableCell sx={{ width: "40%" }} align="left">
                <OpenWith
                  size="small"
                  sx={{
                    p: 1,
                    mr: 1,
                    color: colors.common.black,
                    cursor: "all-scroll",
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
