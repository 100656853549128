import { Box, Button } from '@mui/material';
import NoticeCard from 'components/cards/NoticeCard';
import React, { useEffect, useState } from 'react';
import { useHonsNotice } from '../../../hooks/useHonsNotice';
import AddHonsNotice from './hons-notice/AddHonsNotice';
import EditHonsNotice from './hons-notice/EditHonsNotice';

const HonsNotice = ({ objectKey }) => {
  const { notices, getAllNotices, removeNotice } = useHonsNotice();

  const [showAddModal, setShowAddModal] = useState(false);

  const [editData, setEditData] = useState(null);

  console.log({ notices });

  function onDeletePress(id) {
    if (window.confirm('Are you sure?')) {
      removeNotice(id, objectKey);
    }
  }

  const onEditPress = (notice) => {
    console.log('pressed', notice);
    setEditData(notice);
  };

  useEffect(() => {
    getAllNotices(objectKey);
  }, []);

  return (
    <Box p={2}>
      <AddHonsNotice
        open={showAddModal}
        objectKey={objectKey}
        handleClose={() => setShowAddModal(false)}
      />
      <EditHonsNotice
        notice={editData}
        open={!!editData}
        objectKey={objectKey}
        handleClose={() => setEditData(null)}
      />
      <Button variant="contained" onClick={() => setShowAddModal(true)}>
        Add new notice
      </Button>
      {notices?.map((notice) => {
        return (
          <NoticeCard
            onDeletePress={onDeletePress}
            onEditPress={onEditPress}
            key={notice._id}
            notice={notice}
          />
        );
      })}
    </Box>
  );
};

export default HonsNotice;
