import PreviewCommon from 'components/common/PreviewCommon';
import React from 'react';

const CitizenCharter = () => {
  return (
    <div>
      <PreviewCommon itemKey={'citizenCharter'} showImages />
    </div>
  );
};

export default CitizenCharter;
