import React from 'react';
import {
  Box,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Button,
} from '@mui/material';
import { useAdminList } from './useAdminList';
import { Delete } from '@mui/icons-material';
import adminData from './adminData';

const AllAdmins = () => {
  const { admins, removeAdmin } = useAdminList();

  return (
    <Box sx={{ border: '1px solid #ddd', p: 4, mt: 2 }}>
      <TableContainer>
        <Table
          sx={{
            minWidth: 500,
            width: { xs: '100%', lg: '100%' },
            border: '1px solid #e0e0e0',
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Department</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins?.map((admin) => {
              return (
                <TableRow
                  key={admin._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ width: 'auto' }} scope="row">
                    {admin.name}
                  </TableCell>
                  <TableCell sx={{ width: 'auto' }} scope="row">
                    {admin.userId}
                  </TableCell>
                  <TableCell sx={{ width: 'auto' }} scope="row">
                    {
                      adminData.find(
                        (data) => data.objectKey === admin.assignedTo
                      )?.title
                    }
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() => removeAdmin(admin._id)}
                      color="error"
                      startIcon={<Delete />}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AllAdmins;
