import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from 'components/common/TabPanelCommon';
import StudentList from './StudentList';
import PageTitle from 'components/header/PageTitle';
import PendingStudents from './PendingStudents';
import { useAuth } from 'hooks/useAuth';

const StudentDatabase = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_, v) => setValue(v);

  const { profile, role } = useAuth();

  const isReadOnly = role === 'officeAdmin';

  if (role === 'deptAdmin' && profile?.assignedTo !== 'studentDBAdmin') {
    return <p>Invalid access</p>;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <PageTitle title="Student Database" color={'primary.main'} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 3 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="All Students" />
          <Tab label="Pending for Approval" disabled={isReadOnly} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <StudentList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PendingStudents />
      </TabPanel>
    </Box>
  );
};

export default StudentDatabase;
