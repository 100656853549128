import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table,
  TableHead,
  Avatar,
  IconButton,
} from '@mui/material';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import ImageUploader from 'components/common/ImageUploader';
import { toast } from 'react-toastify';
import { useSKillDev } from 'hooks/useSkillDev';
import { useEffect } from 'react';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { colors } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Edit } from '@mui/icons-material';
import { config } from 'utils/config';

const SkillDevelopment = () => {
  const [photo, setPhoto] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [linkText, setLinkText] = useState('');

  const {
    skillDevs,
    isLoading,
    isAdding,
    addSkillDev,
    removeSkillDev,
    getAllSkillDevs,
  } = useSKillDev();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!photo || !description || !link || !linkText) {
      return toast.error(`All fields are required`);
    }
    addSkillDev({ photo, description, link, linkText }, () => {
      setPhoto('');
      setDescription('');
      setLink('');
      setLinkText('');
    });
  };

  const onUploadImage = (result) => {
    setPhoto(result?.url);
  };

  const onRemove = (id) => {
    if (window.confirm('Are you sure to remove')) {
      removeSkillDev(id);
    }
  };

  useEffect(() => {
    getAllSkillDevs();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Box sx={{ width: '90%', margin: '0 auto' }}>
        <Typography textAlign="center">Skill Development</Typography>
        <Stack spacing={1} mt={2} component="form" onSubmit={handleSubmit}>
          <ImageUploader onUploadImage={onUploadImage} />
          <TextField
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Short Description"
            fullWidth
          />
          <TextField
            name="description"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            label="Link (https://www.example.com)"
            fullWidth
            maxRows={4}
          />
          <TextField
            name="linkText"
            value={linkText}
            onChange={(e) => setLinkText(e.target.value)}
            label="Link Button Text"
            fullWidth
          />
          <LoadingButton loading={isAdding} variant="contained" type="submit">
            Add New
          </LoadingButton>
        </Stack>

        <Table
          sx={{
            minWidth: 500,
            marginTop: 2,
            width: { xs: '100%', lg: '80%', xl: '70%' },
            border: '1px solid #e0e0e0',
            overflow: 'auto',
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '25%' }}>Image</TableCell>
              <TableCell sx={{ width: '25%' }} align="left">
                Description
              </TableCell>
              <TableCell sx={{ width: '25%' }} align="left">
                Link
              </TableCell>
              <TableCell sx={{ width: '25%' }} align="left">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skillDevs.map((item, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ width: '25%' }} component="th" scope="row">
                  <Avatar
                    sx={{ height: 80, width: 80, border: '1px solid #ddd' }}
                    src={config.fileUrl + item.photo}
                  />
                </TableCell>
                <TableCell sx={{ width: '25%' }} align="left">
                  {item.description}
                </TableCell>
                <TableCell sx={{ width: '25%' }} align="left">
                  {item.link}({item.linkText})
                </TableCell>
                <TableCell sx={{ width: '25%' }} align="left">
                  <Stack
                    direction="row"
                    justifyContent="Start"
                    alignItems="center"
                    sx={{ p: 1 }}
                  >
                    {/* <Edit
                      size="small"
                      sx={{
                        borderRadius: '50%',
                        p: 1,
                        mr: 1,
                        color: colors.common.white,
                        bgcolor: colors.green[500],
                      }}
                    /> */}
                    <IconButton onClick={() => onRemove(item._id)}>
                      <Delete
                        size="small"
                        sx={{
                          borderRadius: '50%',
                          p: 1,
                          color: colors.common.white,
                          bgcolor: colors.red[500],
                        }}
                      />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  );
};

export default SkillDevelopment;
