import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import DrawerMenu from 'components/layout/DrawerMenu';
import TopBar from 'components/layout/TopBar';
import { useAuth } from 'hooks/useAuth';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useCommon } from 'hooks/useCommon';
import routes from 'data/routes';
import { Home } from '@mui/icons-material';

const DeptAdminLayout = () => {
  const [loaded, setLoaded] = React.useState(false);

  const { isLoading: commonLoading, getAllItems } = useCommon();

  const { isLoggedIn, role, profile } = useAuth();

  const [showMenu, setShowMenu] = useState(false);

  React.useEffect(() => {
    getAllItems();
    setLoaded(true);
  }, []);

  if (!isLoggedIn || role !== 'deptAdmin') {
    return <Navigate to="/login" />;
  }

  if (!loaded || commonLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <DrawerMenu
        sx={{ width: 200 }}
        menuItems={[
          {
            id: '1',
            title: 'Home Page',
            route: routes.DEPT_ADMIN_HOME,
            icon: <Home />,
          },
          {
            id: '13',
            title: 'প্রশাসনিক',
            route: routes.DEPT_ADMIN_ADMINIS,
            icon: <Home />,
          },
          {
            id: '1434',
            title: 'কলেজ',
            route: routes.DEPT_ADMIN_COLLEGE,
            icon: <Home />,
          },
          {
            id: '134',
            title: 'স্পোর্টস কর্ণার',
            route: routes.DEPT_ADMIN_SPORTS,
            icon: <Home />,
          },
          {
            id: '13234',
            title: 'Library',
            route: routes.DEPT_ADMIN_LIBRARY,
            icon: <Home />,
          },
          {
            id: 'stDB',
            title: 'Student Database',
            route: routes.DEPT_ADMIN_STUDENT_DB,
            icon: <Home />,
          },
        ]}
        isOpen={showMenu}
        onClose={() => setShowMenu(false)}
      />
      <TopBar onOpen={() => setShowMenu(true)} />
      <Outlet />
    </>
  );
};

export default DeptAdminLayout;
