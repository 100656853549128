import React from "react";
import { Stack } from "@mui/material";
import ContentWrapper from "./ContentWrapper";
import ContentInfo from "./ContentInfo";
import MultipleImageAddButton from "components/input/MultipleImageAddButton";

const OtherContent = () => {
  const [selectedImage, setSelectedImage] = React.useState([]);
  const [selectedNotice, setSelectedNotice] = React.useState([]);

  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selctfiles = e.target.files;
      const selectedArray = Array.from(selctfiles);
      setSelectedImage((prevState) => prevState.concat(selectedArray));
    }
  };
  const noticeChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selctfiles = e.target.files;
      const selectedArray = Array.from(selctfiles);
      setSelectedNotice((prevState) => prevState.concat(selectedArray));
    }
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = (image) => {
    setSelectedImage(selectedImage.filter((file) => file !== image));
  };
  const removeSelectedNotice = (image) => {
    setSelectedNotice(selectedNotice.filter((file) => file !== image));
  };
  console.log("selectdeImage", selectedImage, "selectdeNotice", selectedNotice);

  return (
    <>
      <ContentWrapper title="পাঠ্যক্রম">
        <Stack direction="column" sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            sx={{ height: "100%", margin: "1rem" }}
          >
            <MultipleImageAddButton imageChange={imageChange} />
          </Stack>
          <ContentInfo
            selectedImage={selectedImage}
            removeSelectedImage={removeSelectedImage}
          />
        </Stack>
      </ContentWrapper>
      <ContentWrapper title="নোটিশ">
        <Stack direction="column" sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            sx={{ height: "100%", margin: "1rem" }}
          >
            <MultipleImageAddButton imageChange={noticeChange} />
          </Stack>
          <ContentInfo
            selectedImage={selectedNotice}
            removeSelectedImage={removeSelectedNotice}
          />
        </Stack>
      </ContentWrapper>
    </>
  );
};
export default OtherContent;
