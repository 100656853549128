import React, { useEffect } from 'react';
import { Stack, Button, Grid } from '@mui/material';
import SliderImage from 'components/cards/SliderImage';
import SliderUpload from 'components/modals/SliderUpload';
import PageTitle from 'components/header/PageTitle';
import { useSliders } from 'hooks/useSliders';
import { LoadingSpinner } from 'components/common/LoadingSpinner';

const HomeSliders = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isLoading, sliders, getAllSliders } = useSliders();

  useEffect(() => {
    getAllSliders();
  }, []);

  console.log(sliders);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <PageTitle color="primary.main" title="Home Sliders" />
      </Stack>
      <Stack direction="row" sx={{ padding: '1rem 0' }}>
        <Button onClick={handleOpen} variant="contained" color="primary">
          Add New
        </Button>
        <SliderUpload open={open} handleClose={handleClose} />
      </Stack>
      <Grid container spacing={2}>
        {sliders?.length > 0 &&
          sliders.map((slider, index) => (
            <SliderImage key={index} url={slider} />
          ))}
      </Grid>
    </>
  );
};

export default HomeSliders;
