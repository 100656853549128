import { Alert, Stack } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const image = '/images/404.svg';

const FourOhFour = () => {
  return (
    <Stack
      sx={{ minHeight: '100vh' }}
      alignItems="center"
      justifyContent="center"
      spacing={5}
    >
      <img src={image} alt="Not found" style={{ width: '200px' }} />
      <Alert severity="error">Something went wrong!</Alert>
      <Link to="/">Go To home</Link>
    </Stack>
  );
};

export default FourOhFour;
