import React from "react";
import { Stack, Button, Box } from "@mui/material";
import AddTeacherModal from "components/modals/AddTeacherModal";
import TeachersList from "components/tables/TeachersListTable";
import TeacherListTableDnd from "components/drag-drop/TeacherListTableDnd";

const TeacherContent = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Stack direction="row" sx={{ padding: "1rem 0" }}>
        <Button onClick={handleOpen} variant="contained" color="primary">
          Add new
        </Button>
        <AddTeacherModal open={open} handleClose={handleClose} />
      </Stack>
      <Box sx={{ width: "100%", overflowX: { xs: "scroll", md: "hidden " } }}>
        <TeacherListTableDnd />
      </Box>
    </>
  );
};
export default TeacherContent;
