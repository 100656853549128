import React from "react";
import { IconButton, colors } from "@mui/material";
import { Add } from "@mui/icons-material";

const MultipleImageAddButton = ({ imageChange }) => {
  return (
    <>
      <IconButton
        variant="contained"
        component="label"
        sx={{
          borderRadius: 1,
          padding: "1rem",
          bgcolor: colors.blue[50],
          color: "primary.main",
          fontSize: "5rem",
          "&:hover": {
            bgcolor: "primary.dark",
            color: colors.common.white,
          },
        }}
      >
        <Add fontSize="inherit" />

        <input
          accept="image/*"
          type="file"
          onChange={imageChange}
          hidden
          multiple
        />
      </IconButton>
    </>
  );
};

export default MultipleImageAddButton;
