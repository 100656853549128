const routes = {
  /* Guest */
  WELCOME: '/',
  LOGIN: '/login',
  LANDING: '/',

  /* Admin */
  DASHBOARD: '/dashboard',
  ADMIN_LIST: '/admin-list',
  HOME_SLIDERS: '/home-sliders',
  COLLEGE: '/college',
  NOTICE: '/notice',
  BONGOBONDHU: '/bongobondhu',
  LIBRARY: '/library',
  TEACHERS_LIST: '/teachers-list',
  STUDENT_LIST: '/student-list',
  SKILL_DEVELOPMENT: '/skill-development',
  STUDENT_DATABASE: '/student-database',
  STUDENT_BANKING: '/student-banking',
  DEPARTMENT_INFO: '/department-info',
  SCIENCE_DIVISION: '/science',
  BUS_CAR: '/bus-car',
  CITIZEN_CHARTER: '/citizen-charter',
  PUBLICATIONS: '/publications',
  INFO_RIGHT: '/info-right',
  SPORT_CORNER: '/sports',
  ONLINE_ADMISSION: '/online-admission',
  ADMINISTRATIVE: '/administrative',
  HSC_DATA: '/hsc-data',
  HONS_DATA: '/hons-data',
  CLUB_ADD: '/club-add',
  CLUB_EDIT: '/club-edit',

  DEPT_ADMIN: '/department-admin',
  DEPT_ADMIN_HOME: '/department-admin/home',
  DEPT_ADMIN_HSC_DATA: '/department-admin/hsc',
  DEPT_ADMIN_HONS_DATA: '/department-admin/hons',
  DEPT_ADMIN_ADMINIS: '/department-admin/administrative',
  DEPT_ADMIN_SPORTS: '/department-admin/sports',
  DEPT_ADMIN_COLLEGE: '/department-admin/college',
  DEPT_ADMIN_LIBRARY: '/department-admin/library',
  DEPT_ADMIN_STUDENT_DB: '/department-admin/student-db',
  DEPT_ADMIN_CLUB_ADD: '/department-admin/club-add',
  DEPT_ADMIN_CLUB_EDIT: '/department-admin/club-edit',
};

export default routes;
