import React from 'react';
import { toast } from 'react-toastify';
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box } from '@mui/system';
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import { config } from 'utils/config';
import { useCommon } from 'hooks/useCommon';

const FileSelector = ({ file, setFile, label, multiple, onlyImage }) => {
  const { removeFile } = useCommon();

  async function onFileSelect(e) {
    const file = e.target.files[0];

    if (!file) return;

    if (file.size > 1024 * 1024 * 20)
      return toast.error('File size cannot exceed more than 20MB');

    setFile(file);
  }

  const onRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFile(null);
    if (file && !file.type) {
      removeFile(file);
    }
  };

  return (
    <>
      <Stack direction={'column'} spacing={1} py={1}>
        <Stack>
          {onlyImage ? (
            <Typography component="label">{label} Image</Typography>
          ) : (
            <Typography component="label">
              {label} Image/PDF {multiple ? '(Multiple)' : '(Single)'}
            </Typography>
          )}
        </Stack>
        <Stack>
          <Box
            component={'label'}
            sx={{
              cursor: 'pointer',
              border: '1px dashed gray',
              p: 1,
              borderRadius: 1,
              position: 'relative',
              alignSelf: 'flex-start',
            }}
          >
            <input
              hidden
              disabled={file}
              accept={onlyImage ? 'image/*' : 'image/*,.pdf'}
              type="file"
              onChange={onFileSelect}
            />
            {file && (
              <IconButton
                onClick={(e) => onRemove(e)}
                color="error"
                sx={{
                  position: 'absolute',
                  right: -20,
                  top: -20,
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            {file ? (
              <Stack>
                {file.type ? (
                  file?.type?.includes('application/pdf') ? (
                    <PictureAsPdfIcon
                      color="primary"
                      sx={{ height: 60, width: 60 }}
                    />
                  ) : (
                    <Avatar
                      sx={{ height: 60, width: 60 }}
                      src={URL.createObjectURL(file)}
                    />
                  )
                ) : file.includes('.pdf') ? (
                  <PictureAsPdfIcon
                    color="primary"
                    sx={{ height: 60, width: 60 }}
                  />
                ) : (
                  <Avatar
                    sx={{ height: 60, width: 60 }}
                    src={config.fileUrl + file}
                  />
                )}
              </Stack>
            ) : (
              <UploadIcon sx={{ height: 60, width: 60, color: 'gray' }} />
            )}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default FileSelector;
