import React, { useEffect } from 'react';
import { Pagination, Stack, Button, IconButton } from '@mui/material';
import StudentListTable from './StudentListTable';
import { useStudent } from 'hooks/useStudent';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import SearchStudents from './SearchStudents';
import ClearIcon from '@mui/icons-material/Clear';
const StudentList = () => {
  const {
    isLoading,
    data,
    getAllStudents,
    searchedData,
    searching,
    clearSearch,
  } = useStudent();

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getAllStudents(page);
  }, [page]);

  if (isLoading || !data) {
    return <LoadingSpinner />;
  }

  return (
    <Stack justifyContent={'center'} alignItems="center" spacing={3} px={3}>
      <SearchStudents />
      {searching && <LoadingSpinner />}
      {searchedData && (
        <Button onClick={clearSearch} color="error" startIcon={<ClearIcon />}>
          Clear search
        </Button>
      )}
      <StudentListTable students={searchedData || data?.students} />
      <Pagination
        count={searchedData ? 1 : data?.totalPages}
        page={page}
        onChange={handleChange}
      />
    </Stack>
  );
};

export default StudentList;
