import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useCommon } from 'hooks/useCommon';
import TabPanel from 'components/common/TabPanelCommon';
import PreviewCommon from 'components/common/PreviewCommon';

const BongobondhuPage = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_, v) => setValue(v);

  const { items } = useCommon();
  console.log({ items });

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="বঙ্গবন্ধু ও বাংলাদেশ" />
            <Tab label="মুক্তিযোদ্ধ" />
            <Tab label="শেখ রাসেল" />
            <Tab label="শহীদ বুদ্ধিজীবী" />
            <Tab label="মূরাল হৃদয়ে বঙ্গবন্ধু" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PreviewCommon itemKey={'bongobondhuNbd'} showBody showImages />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PreviewCommon itemKey={'muktijhoddho'} showBody showImages />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PreviewCommon itemKey={'reasselCorner'} showBody showImages />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PreviewCommon itemKey={'shohidBuddhijibi'} showBody showImages />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PreviewCommon itemKey={'muralBongobondhu'} showBody showImages />
        </TabPanel>
      </Box>
    </div>
  );
};

export default BongobondhuPage;
