export const hscData = [
  { id: 1, title: 'একাদশ - বিজ্ঞান বিভাগ', objectKey: 'science11' },
  { id: 2, title: 'একাদশ - মানবিক বিভাগ', objectKey: 'humanities11' },
  { id: 3, title: 'একাদশ - বাবসায় শিক্ষা বিভাগ', objectKey: 'business11' },
  { id: 4, title: 'দ্বাদশ  - বিজ্ঞান বিভাগ', objectKey: 'science12' },
  { id: 5, title: 'দ্বাদশ  - মানবিক বিভাগ', objectKey: 'humanities12' },
  { id: 6, title: 'দ্বাদশ  - বাবসায় শিক্ষা বিভাগ', objectKey: 'business12' },
];

export const honsData = [
  { id: 1, title: 'বাংলা বিভাগ', objectKey: 'bengali' },
  { id: 2, title: 'ইংরেজি বিভাগ', objectKey: 'english' },
  { id: 3, title: 'ইতিহাস বিভাগ', objectKey: 'history' },
  {
    id: 4,
    title: 'ইসলামের ইতিহাস ও সংস্কৃতি বিভাগ',
    objectKey: 'islamHistory',
  },
  {
    id: 5,
    title: 'আরবি ও ইসলামিক স্টাডিজ',
    objectKey: 'islamicStudies',
  },
  {
    id: 6,
    title: 'দর্শন বিভাগ',
    objectKey: 'philosophy',
  },
  {
    id: 7,
    title: 'সংস্কৃত বিভাগ',
    objectKey: 'sanskrit',
  },
  {
    id: 8,
    title: 'সমাজবিজ্ঞান বিভাগ',
    objectKey: 'Sociology',
  },
  {
    id: 9,
    title: 'সমাজকর্ম বিভাগ',
    objectKey: 'socialWork',
  },
  {
    id: 10,
    title: 'রাষ্ট্রবিজ্ঞান বিভাগ',
    objectKey: 'political',
  },
  {
    id: 11,
    title: 'অর্থনীতি বিভাগ',
    objectKey: 'economy',
  },
  {
    id: 12,
    title: 'পদার্থবিদ্যা বিভাগ',
    objectKey: 'physics',
  },
  {
    id: 13,
    title: 'রসায়ন বিভাগ',
    objectKey: 'chemistry',
  },
  {
    id: 14,
    title: 'গণিত বিভাগ',
    objectKey: 'mathematics',
  },
  {
    id: 15,
    title: 'পরিংখ্যান বিভাগ',
    objectKey: 'statistics',
  },
  {
    id: 16,
    title: 'উদ্ভিদবিজ্ঞান বিভাগ',
    objectKey: 'botany',
  },
  {
    id: 17,
    title: 'প্রাণিবিদ্যা বিভাগ',
    objectKey: 'zoology',
  },
  {
    id: 18,
    title: 'তথ্য ও যোগাযোগ প্রযুক্তি বিভাগ',
    objectKey: 'ict',
  },
  {
    id: 19,
    title: 'হিসাববিজ্ঞান বিভাগ',
    objectKey: 'accounting',
  },
  {
    id: 20,
    title: 'ব্যবস্থাপনা বিভাগ',
    objectKey: 'Management',
  },
  {
    id: 21,
    title: 'ফিসান্স & ব্যাংকিং বিভাগ',
    objectKey: 'financeBanking',
  },
  {
    id: 22,
    title: 'মার্কেটিং বিভাগ',
    objectKey: 'marketing',
  },
];

export const degreeData = [
  { id: 1, title: 'বিবিএস', objectKey: 'degreeBbs' },
  { id: 2, title: 'বিএসএস', objectKey: 'degreeBss' },
  { id: 3, title: 'বিএ', objectKey: 'degreeBa' },
  { id: 3, title: 'বিএসসি', objectKey: 'degreeBsc' },
];
