import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PreviewCommon from 'components/common/PreviewCommon';
import { useAuth } from 'hooks/useAuth';

const Library = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_, v) => setValue(v);

  const { profile, role } = useAuth();

  if (role === 'deptAdmin' && profile?.assignedTo !== 'libraryAdmin') {
    return <p>Invalid access</p>;
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="HSC" />
            <Tab label="Hons" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PreviewCommon itemKey={'libraryHsc'} showImages />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PreviewCommon itemKey={'libraryHons'} showImages />
        </TabPanel>
      </Box>
    </div>
  );
};

export default Library;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
