import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import AllAdmins from './AllAdmins';
import { useAdminList } from './useAdminList';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import adminData from './adminData';
import { useAuth } from 'hooks/useAuth';

const AdminList = () => {
  const [assignedTo, setAssignedTo] = useState('');
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const { role } = useAuth();

  const { getAllAdmins, addAdmin, isLoading, isAdding } = useAdminList();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!assignedTo || !userId || !password) {
      return toast.error('All fields are required');
    }
    if (password.length < 6) return toast.error('Password must be at least 6');
    addAdmin({ name, userId, password, assignedTo }, () => {
      setName('');
      setUserId('');
      setAssignedTo('');
      setPassword('');
    });
  };

  useEffect(() => {
    getAllAdmins();
  }, []);

  if (isLoading) return <LoadingSpinner />;

  if (role === 'officeAdmin') {
    return <p>Invalid access</p>;
  }

  return (
    <Container maxWidth="lg">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ border: '1px solid #ddd', p: 4 }}
      >
        <Typography align="center" variant="h6">
          Assign an Admin
        </Typography>
        <Stack mt={3} spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Input
            placeholder="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            placeholder="User Id"
            fullWidth
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <Input
            placeholder="Password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>Assigned to</InputLabel>
            <Select
              value={assignedTo}
              label="Assign to"
              onChange={(e) => setAssignedTo(e.target.value)}
            >
              {adminData.map((item, idx) => (
                <MenuItem key={idx} value={item.objectKey}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LoadingButton
            loading={isAdding}
            type="submit"
            fullWidth
            variant="contained"
          >
            Assign
          </LoadingButton>
        </Stack>
      </Box>
      <AllAdmins />
    </Container>
  );
};

export default AdminList;
