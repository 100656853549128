import React from 'react';
import { Typography } from '@mui/material';
import PreviewCommon from 'components/common/PreviewCommon';

const OnlineAdmission = () => {
  return (
    <div>
      <div>
        <Typography textAlign="center" fontSize={24}>
          অনলাইন ভর্তি/ ফর্ম পূরণ
        </Typography>
        <Typography mt={2} textAlign="center">
          Format - Link Text,Link <br /> (separate by enter, no space)
        </Typography>
        <PreviewCommon itemKey={'onlineAdmission'} showTextArea />
      </div>
    </div>
  );
};

export default OnlineAdmission;
