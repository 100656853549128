let baseUrl = 'http://localhost:8282/api';
let imageUrl = '';
let fileUrl = '';

if (process.env.NODE_ENV === 'production') {
  baseUrl = '/api';
  imageUrl = 'https://amarcvgc.com/images/';
  fileUrl = 'https://amarcvgc.com/';
} else {
  baseUrl = 'http://localhost:8181/api';
  imageUrl = 'http://localhost:8181/images';
  fileUrl = 'http://localhost:8181';
}

export const config = {
  baseUrl,
  imageUrl,
  fileUrl,
};
