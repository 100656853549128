import { Typography } from '@mui/material';
import React from 'react';
import PreviewCommon from 'components/common/PreviewCommon';

const StudentBanking = () => {
  return (
    <div>
      <Typography textAlign="center" fontSize={24}>
        Student Banking
      </Typography>
      <Typography mt={2} textAlign="center">
        Format - Link Text,Link <br /> (separate by enter, no space)
      </Typography>
      <PreviewCommon itemKey={'studentBanking'} showTextArea />
    </div>
  );
};

export default StudentBanking;
