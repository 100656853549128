import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PreviewCommon from 'components/common/PreviewCommon';
import TabPanel from 'components/common/TabPanelCommon';
import Club from './Club';
import { useAuth } from 'hooks/useAuth';

const CollegeHome = () => {
  const { role, profile } = useAuth();

  const isClubAdmin =
    role === 'deptAdmin' && profile?.assignedTo === 'clubAdmin';

  const [value, setValue] = React.useState(isClubAdmin ? 1 : 0);
  const handleChange = (_, v) => setValue(v);

  if (role === 'deptAdmin' && profile?.assignedTo !== 'clubAdmin') {
    return <p>Invalid access</p>;
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="প্রাক্তন অধ্যক্ষগণ" disabled={isClubAdmin} />
            <Tab label="সংঘঠন সমূহ " />
            <Tab label="একাডেমিক ক্যালেন্ডার" disabled={isClubAdmin} />
            <Tab label="প্রোসপেক্টাস" disabled={isClubAdmin} />
            <Tab label="শুভেচ্ছা বার্তা" disabled={isClubAdmin} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PreviewCommon itemKey={'exPrinciples'} showTextArea />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Club />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PreviewCommon
            itemKey={'academicCalendar'}
            showImages
            warning="Upload single PDF Here"
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PreviewCommon
            itemKey={'prospectus'}
            showImages
            warning="Upload single PDF Here"
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PreviewCommon
            itemKey={'greetings'}
            showImages
            showTextArea
            warning="1-Message 2. Sign"
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default CollegeHome;
