import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.color || "#555"};
`;
export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${(props) => props.color || "#555"};
`;
