const {
  hscData,
  honsData,
  degreeData,
} = require('../department-info/constants');

const adminData = [
  ...hscData,
  ...honsData,
  ...degreeData,
  { id: '23232', title: 'প্রশাসনিক Admin', objectKey: 'administrative' },
  { id: '1100', title: 'স্পোর্টস কর্ণার Admin', objectKey: 'sportsAdmin' },
  { id: '2300', title: 'Club Admin', objectKey: 'clubAdmin' },
  { id: '123200', title: 'Library Admin', objectKey: 'libraryAdmin' },
  { id: '1234', title: 'Student Database Admin', objectKey: 'studentDBAdmin' },
];

export default adminData;
