import React from 'react';
import { Stack, Button, Box } from '@mui/material';
import TabContainer from '../../components/tabs/Tabs';
import TabItem from '../../components/tabs/TabItem';
import AddNoticeModal from 'components/modals/AddNoticeModal';
import PageTitle from 'components/header/PageTitle';
import { useNotice } from 'hooks/useNotice';
import NoticeCard from 'components/cards/NoticeCard';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import EditNoticeModal from 'components/modals/EditNoticeModal';

const tabData = [
  { id: 1, title: 'কেন্দ্রীয় নোটিশ' },
  { id: 2, title: 'উচ্চ মাধ্যমিক' },
  { id: 3, title: 'ডিগ্রি শাখা' },
];

const Notice = () => {
  const { isLoading, getAllNotices, notices, removeNotice } = useNotice();

  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [editData, setEditData] = React.useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getAllNotices();
  }, []);

  function onEditPress(data) {
    console.log(data);
    setEditData(data);
    setIsEditOpen(true);
  }

  function onDeletePress(id) {
    if (window.confirm('Are you sure?')) {
      removeNotice(id);
    }
  }

  return (
    <div>
      <PageTitle color="black" title="নোটিশ" />
      {isLoading && <LoadingSpinner />}
      <Stack direction="row" sx={{ padding: '1rem 0' }}>
        <Button onClick={handleOpen} variant="contained" color="primary">
          নতুন নোটিশ
        </Button>
        <AddNoticeModal open={open} handleClose={handleClose} />
        <EditNoticeModal
          notice={editData}
          open={isEditOpen}
          handleClose={() => setIsEditOpen(false)}
        />
      </Stack>
      <Box sx={{ width: '100%' }}>
        <TabContainer
          tabList={tabData}
          value={value}
          handleChange={handleChange}
        />
        <TabItem value={value} index={0}>
          {notices?.map((notice) => {
            if (notice.type !== 'central') return null;
            return (
              <NoticeCard
                onDeletePress={onDeletePress}
                onEditPress={onEditPress}
                key={notice._id}
                notice={notice}
              />
            );
          })}
        </TabItem>
        <TabItem value={value} index={1}>
          {notices?.map((notice) => {
            if (notice.type !== 'hsc') return null;
            return (
              <NoticeCard
                onEditPress={onEditPress}
                onDeletePress={onDeletePress}
                ey={notice._id}
                notice={notice}
              />
            );
          })}
        </TabItem>
        <TabItem value={value} index={2}>
          {notices?.map((notice) => {
            if (notice.type !== 'hons') return null;
            return (
              <NoticeCard
                onEditPress={onEditPress}
                onDeletePress={onDeletePress}
                ey={notice._id}
                notice={notice}
              />
            );
          })}
        </TabItem>
      </Box>
    </div>
  );
};

export default Notice;
