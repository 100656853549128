import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import DrawerMenu from 'components/layout/DrawerMenu';
import TopBar from 'components/layout/TopBar';
import menuItems from 'data/menuItems';
import { useAuth } from 'hooks/useAuth';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useCommon } from 'hooks/useCommon';
import routes from 'data/routes';

const AdminLayout = () => {
  const [loaded, setLoaded] = React.useState(false);

  const { isLoading: commonLoading, getAllItems } = useCommon();

  const { isLoggedIn, role } = useAuth();
  const [showMenu, setShowMenu] = useState(false);

  React.useEffect(() => {
    getAllItems();
    setLoaded(true);
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (isLoggedIn && role === 'deptAdmin') {
    return <Navigate to={routes.DEPT_ADMIN_HOME} />;
  }

  if (!loaded || commonLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <DrawerMenu
        menuItems={menuItems}
        isOpen={showMenu}
        onClose={() => setShowMenu(false)}
      />
      <TopBar onOpen={() => setShowMenu(true)} />
      <Outlet />
    </>
  );
};

export default AdminLayout;
