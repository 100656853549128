import React from 'react';
import { Alert, Stack, Typography } from '@mui/material';
import PageTitle from 'components/header/PageTitle';
import DepartmentList from 'pages/admin-pages/department-info/DepartmentList';
import { degreeData, honsData, hscData } from './constants';
import routes from 'data/routes';
import { useAuth } from '../../../hooks/useAuth';

const DepartmentInfo = () => {
  const { role, profile } = useAuth();

  const isOfficeAd = role === 'officeAdmin';

  const hscRoute =
    role === 'deptAdmin' ? routes.DEPT_ADMIN_HSC_DATA : routes.HSC_DATA;
  const honsRoute =
    role === 'deptAdmin' ? routes.DEPT_ADMIN_HONS_DATA : routes.HONS_DATA;

  const activeKey = profile?.assignedTo || 'all';

  return (
    <>
      <PageTitle color="primary.main" title=" Department Information" />
      <Stack
        direction="column"
        sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}
      >
        <Typography variant="h6" color="primary.main" fontWeight={600}>
          উচ্চ মাধ্যমিক শাখা
        </Typography>
        <DepartmentList activeKey={activeKey} list={hscData} route={hscRoute} />
      </Stack>
      <Stack
        direction="column"
        sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}
      >
        <Typography variant="h6" color="primary.main" fontWeight={600}>
          অনার্স শাখা
        </Typography>
        {isOfficeAd ? null : (
          <DepartmentList
            activeKey={activeKey}
            list={honsData}
            route={honsRoute}
          />
        )}
      </Stack>
      <Stack
        direction="column"
        sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}
      >
        <Typography variant="h6" color="primary.main" fontWeight={600}>
          ডিগ্রী (পাস) কোর্সঃ
        </Typography>
        <Alert severity="warning" sx={{ mt: 2 }}>
          এপ-এ ডিগ্রী শাখার শুধুমাত্র<b> নোটিশ, পাঠ্যক্রম ও সেমিনার</b> শো করবে
        </Alert>
        {isOfficeAd ? null : (
          <DepartmentList
            activeKey={activeKey}
            list={degreeData}
            route={honsRoute}
          />
        )}
      </Stack>
    </>
  );
};

export default DepartmentInfo;
