import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useClubs = create((set, get) => ({
  isLoading: false,
  isAdding: false,
  clubs: [],
  singleClub: {},

  addClub: async (formData, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.post('/admin/clubs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('club uploaded');
      set(() => ({ isAdding: false }));
      get().getAllClubs();
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  removeClub: async (id) => {
    try {
      await apiClient.delete(`/admin/clubs/${id}`);
      toast.success('club removed');
      get().getAllClubs();
    } catch (error) {
      console.log({ error });
    }
  },

  updateClub: async (formData) => {
    try {
      await apiClient.put('/admin/clubs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('club updated');
      get().getAllClubs();
    } catch (error) {
      console.log({ error });
    }
  },

  getAllClubs: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('/admin/clubs');
      set(() => ({ isLoading: false, clubs: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  getClubById: async (id) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get(`/admin/clubs/${id}`);
      set(() => ({ isLoading: false, singleClub: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  removeSlider: async (url) => {
    const _url = encodeURIComponent(url);
    try {
      await apiClient.delete(`/admin/clubsclubs${_url}`);
      get().getAllClubs();
    } catch (error) {}
  },
}));
