import * as React from 'react';
import { Button, IconButton, Input, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Delete } from '@mui/icons-material';
import { Stack } from '@mui/system';
import ImageUploader from 'components/common/ImageUploader';

const defaultValues = {
  name: 'NAME',
  designation: 'DESIGNATION',
  photo: '',
  phone: '000000000',
  email: 'test@test.com',
};

export default function ClubCommittee({
  committee = [],
  setCommittee = () => {},
}) {
  const handleChange = (newValue, id, key) => {
    const newData = committee.map((single) => {
      if (single.id === id) {
        return { ...single, [key]: newValue };
      }
      return single;
    });
    setCommittee(newData);
  };

  const onAddNew = () => {
    setCommittee((prev) => [
      ...prev,
      { ...defaultValues, serial: prev.length + 1, id: prev.length + 1 },
    ]);
  };

  const onOrder = () => {
    const newData = committee.sort((a, b) => a.serial - b.serial);
    setCommittee([...newData]);
  };

  const onRemove = (id) => {
    const newData = committee.filter((item) => item.id !== id);
    setCommittee([...newData]);
  };

  React.useEffect(() => {
    onOrder();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ p: 3, width: '100%' }}>
      <Typography textAlign="left" my={2}>
        Club committee
      </Typography>{' '}
      <Stack py={2} direction="row" spacing={2}>
        <Button variant="contained" onClick={onAddNew}>
          Add New Member
        </Button>
        <Button onClick={onOrder} variant="outlined" color="secondary">
          Reorder
        </Button>
      </Stack>
      <Table
        sx={{
          minWidth: 800,
          width: '95%',
          border: '1px solid #e0e0e0',
          mr: 2,
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Serial No.</TableCell>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Designation</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Photo</TableCell>
            <TableCell align="center">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflowY: 'unset' }}>
          {committee.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell align="left" sx={{ width: '80px' }}>
                <Input
                  type="number"
                  value={row.serial}
                  onChange={(e) =>
                    handleChange(parseInt(e.target.value), row.id, 'serial')
                  }
                />
              </TableCell>
              <TableCell align="left" sx={{ width: '80px' }}>
                <Input
                  value={row.id}
                  onChange={(e) => handleChange(e.target.value, row.id, 'id')}
                />
              </TableCell>
              <TableCell align="left" sx={{ width: 'auto' }}>
                <Input
                  value={row.name}
                  onChange={(e) => handleChange(e.target.value, row.id, 'name')}
                />
              </TableCell>
              <TableCell align="left" sx={{ width: 'auto' }}>
                <Input
                  value={row.designation}
                  onChange={(e) =>
                    handleChange(e.target.value, row.id, 'designation')
                  }
                />
              </TableCell>
              <TableCell align="left" sx={{ width: 'auto' }}>
                <Input
                  value={row.phone}
                  onChange={(e) =>
                    handleChange(e.target.value, row.id, 'phone')
                  }
                />
              </TableCell>
              <TableCell align="left" sx={{ width: 'auto' }}>
                <Input
                  value={row.email}
                  onChange={(e) =>
                    handleChange(e.target.value, row.id, 'email')
                  }
                />
              </TableCell>
              <TableCell align="left">
                <ImageUploader
                  onUploadImage={(file) =>
                    handleChange(file.url, row.id, 'photo')
                  }
                  imageUrl={row.photo}
                />
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={() => onRemove(row.id)}>
                  <Delete size="small" sx={{ color: 'red' }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
