import * as React from "react";
import { Button, Box, Typography, Modal, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ReactQuillInput from "components/react-quill/ReactQuill";
import { Clear } from "@mui/icons-material";
import DateRangePikerInput from "components/input/DateRangePikerinput";

export default function AddTeacherModal({ open, handleClose }) {
  const [formInputs, setformInputs] = React.useState({
    start: new Date("2014-08-18T21:11:54"),
    end: new Date("2014-08-18T21:11:54"), //new Date(),
    title: "",
  });

  const handleStart = (newValue) => {
    setformInputs({ ...formInputs, start: newValue });
  };

  const handleEnd = (newValue) => {
    setformInputs({ ...formInputs, end: newValue });
  };

  const handleTitle = (newvalue) => {
    setformInputs({ ...formInputs, title: newvalue });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    //  console.log("from handle submit", formInputs);
  };
  React.useEffect(() => {
    // console.log("From use effect", formInputs);
  }, [formInputs]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ overflowY: "auto" }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          {" "}
          <Clear onClick={handleClose} />
        </Box>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center" }}
        >
          New Teacher
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Box sx={{ marginTop: "1rem" }}>
            <TextField
              required
              helperText=" "
              value={formInputs.title}
              onChange={(event) => handleTitle(event.target.value)}
              id="new-notice-title"
              label="Teacher Name"
              sx={{ width: { xs: "100%" } }}
            />
          </Box>
          <Box>
            <DateRangePikerInput
              required={true}
              start={formInputs.start}
              end={formInputs.end}
              handleStart={handleStart}
              handleEnd={handleEnd}
              startLabel={"Start Session"}
              endLabel={"End Session"}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  maxWidth: "500px",
  minHeight: { xs: "300px", md: "400px" },
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};
