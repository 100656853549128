import {
  Avatar,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { config } from 'utils/config';
import { LoadingButton } from '@mui/lab';
import { useCommon } from 'hooks/useCommon';
import { toast } from 'react-toastify';
import apiClient from 'api/apiClient';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { colors, Alert } from '@mui/material';

const EditCommon = ({
  itemKey,
  data,
  showImages,
  showBody,
  onFinish,
  showTextArea,
}) => {
  const { updateItem, isUpdating, removeFile } = useCommon();

  const [body, setBody] = useState(data?.body);
  const [textArea, setTextArea] = useState(data?.textArea);
  const [files, setFiles] = useState(data?.files || []);
  const [caption, setCaption] = useState('');

  function onRemoveImage(url) {
    const filtered = files.filter((img) => img.url !== url);
    setFiles(filtered);
    removeFile(url);
  }

  function onUpdate() {
    updateItem({ key: itemKey, body, files, textArea }, () => {
      onFinish();
    });
  }

  async function onFileSelect(e) {
    const file = e.target.files[0];
    console.log(file.size);
    if (file.size > 1024 * 1024 * 20)
      return toast.error('File size cannot exceed more than 20MB');

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await apiClient.post('/admin/uploadSingle', formData);
      console.log({ response });
      if (response.data?.path) {
        console.log('here', response.data);
        setFiles([...files, { url: response.data.path, caption }]);
      }
      setCaption('');
    } catch (error) {
      console.log('err', error);
    }
    e.target.value = null;
  }

  return (
    <Stack spacing={1}>
      <Typography sx={{ textAlign: 'center', color: 'text.primary' }}>
        Edit Data
      </Typography>

      <Alert severity="info" style={{ marginBottom: 10 }}>
        যেকোন কিছু রিমুভ/এড করার পর অবশ্যই `Update Data` বাটনে প্রেস করবেন
      </Alert>

      {showTextArea && (
        <TextareaAutosize
          value={textArea}
          style={{ minHeight: 200, fontSize: 20 }}
          onChange={(e) => setTextArea(e.target.value)}
        />
      )}

      {/* Body */}
      {showBody && (
        <TextField
          placeholder="Description"
          label="Description"
          multiline
          fullWidth
          maxRows={6}
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      )}

      {/* Images */}
      {showImages && (
        <div>
          <Typography variant="body2" color="text.secondary">
            Image or PDF Upload
          </Typography>
          <Stack
            sx={{ my: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Input
              placeholder="Caption (optional)"
              fullWidth
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            />
            <LoadingButton component="label" sx={{ width: 200 }}>
              Upload new
              <input
                hidden
                accept="image/*,.pdf"
                type="file"
                onChange={onFileSelect}
              />
            </LoadingButton>
          </Stack>
          <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {files?.map((file) => {
              return (
                <ListItem
                  sx={{ border: '1px dashed #ddd', mb: 1 }}
                  key={file?.url}
                  secondaryAction={
                    <IconButton onClick={() => onRemoveImage(file?.url)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      {file?.url?.includes('.pdf') ? (
                        <Avatar sx={{ backgroundColor: 'white' }}>
                          <PictureAsPdfIcon sx={{ color: colors.red[500] }} />
                        </Avatar>
                      ) : (
                        <Avatar
                          alt={`Avatar n°${file}`}
                          src={config.fileUrl + file?.url}
                          sx={{ objectFit: 'contain' }}
                        />
                      )}
                    </ListItemAvatar>
                    <ListItemText primary={file?.caption} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>
      )}
      <LoadingButton
        onClick={onUpdate}
        loading={isUpdating}
        variant="contained"
      >
        Update Data
      </LoadingButton>
    </Stack>
  );
};

export default EditCommon;
