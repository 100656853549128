import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import { useHSC } from 'hooks/useHSC';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

export default function DepartmentList({ list, route, activeKey }) {
  const { getAllHSC, isLoading, data } = useHSC();

  console.log({ activeKey });
  const navigate = useNavigate();

  const toDetailsPage = (item) => {
    navigate(route, {
      state: { ...item, ...data[item.objectKey] },
    });
  };

  React.useEffect(() => {
    getAllHSC();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container spacing={2} my={1}>
      {list.map((item, index) => (
        <Grid key={index} container item xs={6} md={4} direction="column">
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => toDetailsPage(item)}
            disabled={activeKey !== 'all' && item.objectKey !== activeKey}
            type="button"
          >
            <ListItemText sx={{ textAlign: 'center' }} primary={item.title} />
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
