import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { usePendingStudents } from 'hooks/usePendingStudent';
import React, { useEffect, useState } from 'react';
import StudentListTable from './StudentListTable';
import { Pagination, Stack } from '@mui/material';

const PendingStudents = () => {
  const { isLoading, data, getPendingStudents } = usePendingStudents();
  const [page, setPage] = useState('');

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getPendingStudents(page);
  }, [page]);

  if (isLoading || !data) {
    return <LoadingSpinner />;
  }

  return (
    <Stack justifyContent={'center'} alignItems="center" spacing={3} p={3}>
      <StudentListTable students={data?.students} type="pending" />
      <Pagination
        count={data?.totalPages}
        page={page}
        onChange={handleChange}
      />
    </Stack>
  );
};

export default PendingStudents;
