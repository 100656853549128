import React from 'react';
import PageTitle from 'components/header/PageTitle';
import { Stack, Typography } from '@mui/material';
import DepartmentList from './department-info/DepartmentList';
import routes from '../../data/routes';
import { useAuth } from 'hooks/useAuth';

const Administrative = () => {
  const { profile, role } = useAuth();

  console.log(profile);

  if (role === 'deptAdmin' && profile?.assignedTo !== 'administrative') {
    return <p>Invalid access</p>;
  }

  return (
    <div>
      <PageTitle color="primary.main" title="Administrative" />
      <Stack
        direction="column"
        sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}
      >
        <Typography variant="h6" color="primary.main" fontWeight={600}>
          প্রশাসনিক
        </Typography>
        <DepartmentList
          activeKey={'all'}
          list={list}
          route={routes.HONS_DATA}
        />
      </Stack>
    </div>
  );
};

export default Administrative;

export const list = [
  { id: 1, title: 'অধ্যক্ষ', objectKey: 'principle', onlyList: true },
  { id: 2, title: 'উপাধ্যক্ষ', objectKey: 'vicePrinciple', onlyList: true },
  { id: 3, title: 'লাইব্রেরি বিভাগ', objectKey: 'library', onlyList: true },
  {
    id: 4,
    title: 'শারীরিক বিভাগ',
    objectKey: 'physicalEducation',
    onlyList: true,
  },
  { id: 5, title: 'অফিস স্টাফ', objectKey: 'officeStuff', onlyList: true },
  { id: 6, title: 'আইটি বিভাগ', objectKey: 'ITStuff', onlyList: true },
];
