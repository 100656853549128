import React from 'react';
import { Card, CardMedia, colors } from '@mui/material';
import { Clear } from '@mui/icons-material';

const ImagePreviewCard = ({ removeSelectedImage, selectedImage }) => {
  return (
    <Card sx={styles.preview}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'end',
          width: '100%',
          margin: '1rem 0',
        }}
      >
        <Clear
          onClick={removeSelectedImage}
          size="small"
          sx={{
            bgcolor: colors.red[500],
            color: colors.common.white,
            padding: '0.5rem',
            borderRadius: '50%',
            fontSize: '1.5rem',
            cursor: 'pointer',
          }}
        />
      </div>

      <CardMedia
        component="img"
        image={URL.createObjectURL(selectedImage)}
        sx={styles.image}
        alt="Thumb"
      />
    </Card>
  );
};

export default ImagePreviewCard;

// Just some styles
const styles = {
  container: {},
  preview: {
    marginTop: 2,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    alignItems: 'center',
  },
  image: { maxWidth: '100%', maxHeight: 320, objetFit: 'cover' },
  delete: {
    padding: 1,
    width: '100%',
    bgcolor: 'error.main',
  },
};
