import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Grid } from '@mui/material';
import FileSelector from 'components/common/FileSelector';
import { LoadingButton } from '@mui/lab';
import { useHons } from 'hooks/useHons';

const HonsOthers = ({ objectKey, data }) => {
  const { addHonsOthers, isAdding } = useHons();

  const [curriculum, setCurriculum] = useState('');
  const [routine, setRoutine] = useState('');
  const [seminar, setSeminar] = useState('');
  const [notice, setNotice] = useState('');
  const [picture, setPicture] = useState('');

  const onSubmitData = () => {
    const formData = new FormData();
    formData.append('key', objectKey);
    formData.append('curriculum', curriculum || '');
    formData.append('routine', routine || '');
    formData.append('seminar', seminar || '');
    formData.append('picture', picture || '');
    formData.append('notice', notice || '');

    addHonsOthers(formData);
  };

  React.useEffect(() => {
    if (!data) return;
    if (data.curriculum) setCurriculum(data.curriculum);
    if (data.routine) setRoutine(data.routine);
    if (data.seminar) setSeminar(data.seminar);
    if (data.notice) setNotice(data.notice);
    if (data.picture) setPicture(data.picture);
  }, [data]);

  if (!objectKey) return null;

  return (
    <Stack spacing={2}>
      <Grid container spacing={2} p={4}>
        <Grid item xs={6}>
          <FileSelector
            label={'পাঠ্যক্রম '}
            file={curriculum}
            setFile={setCurriculum}
          />
        </Grid>
        <Grid item xs={6}>
          <FileSelector label={'নোটিশ'} file={notice} setFile={setNotice} />
        </Grid>
        <Grid item xs={6}>
          <FileSelector
            label={'ক্লাস রুটিন'}
            file={routine}
            setFile={setRoutine}
          />
        </Grid>
        <Grid item xs={6}>
          <FileSelector label={'ছবি'} file={picture} setFile={setPicture} />
        </Grid>
        <Grid item xs={6}>
          <FileSelector label={'সেমিনার'} file={seminar} setFile={setSeminar} />
        </Grid>
      </Grid>
      <Stack spacing={2} px={2}>
        <LoadingButton
          loading={isAdding}
          onClick={onSubmitData}
          variant="contained"
        >
          Update Information
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default HonsOthers;
