import apiClient from 'api/apiClient';
import { get } from 'lodash';
import appRoutes from 'data/routes';
import { clearValue, getValue, setValue } from 'utils/storage';
import create from 'zustand';

export const useAuth = create((set) => ({
  isLoading: false,
  isLoggedIn: false,
  profile: undefined,
  role: '',

  loginUser: async (formValue, navigate) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.post('user/login', { ...formValue });
      const token = get(response, 'data.data.token', '');
      const role = get(response, 'data.data.profile.role', '');
      const profile = get(response, 'data.data.profile', {});
      setValue('token', token);
      setValue('role', role);
      setValue('profile', profile);
      set(() => ({ isLoading: false, isLoggedIn: true, role, profile }));
      navigate(appRoutes.DASHBOARD);
    } catch (error) {
      set(() => ({ isLoading: false }));
    }
  },

  setLoggedIn: async () => {
    const role = getValue('role');
    const profile = getValue('profile');
    set(() => ({ isLoggedIn: true, role, profile }));
  },

  logoutUser: async (navigate) => {
    clearValue('token');
    clearValue('role');
    set(() => ({ isLoggedIn: false, role: '', profile: undefined }));
    navigate(appRoutes.LOGIN);
  },
}));
