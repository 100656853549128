import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useHSC = create((set, get) => ({
  isLoading: false,
  isAdding: false,
  data: [],

  addHSC: async (formData, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.post('/admin/hsc', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Added');
      set(() => ({ isAdding: false }));
      get().getAllHSC();
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  getAllHSC: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('/admin/hsc');
      set(() => ({ isLoading: false, data: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
