import React from 'react';
import { Box, Grid, Typography, colors, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';

const NoticeCard = ({ notice, onEditPress, onDeletePress }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} sx={{ mt: 3 }}>
      <Grid item xs={2} sm={1} spacing={2}>
        <Box
          sx={{
            width: { xs: '40px', sm: '45px' },
            height: { xs: '40px', sm: '45px' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            fontWeight="600"
            sx={{
              color: colors.blue[500],
              bgcolor: colors.blue[100],
              padding: 2,
              whiteSpace: 'break-spaces',
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {format(new Date(notice.date), 'dd MMM')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} sm={10}>
        <Box sx={{ paddingLeft: 2 }} className={classes.container}>
          <Typography fontWeight="500" className={classes.multiLineEllipsis}>
            {notice.title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2} sm={1}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => onEditPress(notice)}>
            <Edit
              fontSize={'small'}
              sx={{
                bgcolor: colors.green[500],
                color: colors.common.white,
                padding: 0.5,
                marginRight: { xs: 0.25, sm: 0.5, md: 0.75, lg: 1 },
                borderRadius: '50%',
              }}
            />
          </IconButton>
          <IconButton onClick={() => onDeletePress(notice._id)}>
            <Delete
              fontSize={'small'}
              sx={{
                bgcolor: colors.red[500],
                color: colors.common.white,
                padding: 0.5,

                borderRadius: '50%',
              }}
            />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NoticeCard;

const LINES_TO_SHOW = 2;

const useStyles = makeStyles({
  container: { maxWidth: '100%' },
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': LINES_TO_SHOW,
    '-webkit-box-orient': 'vertical',
  },
});
