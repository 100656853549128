import React from "react";
import { Stack, Typography, Box, colors, IconButton } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

const ContentWrapper = ({ children, title }) => {
  return (
    <>
      <Box sx={{ bgcolor: colors.grey[300], marginTop: "1rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="end"
          sx={{ p: 0.5 }}
        >
          <Typography
            variant="h6"
            sx={{
              padding: "0.5rem ",
              fontWeight: "600",
              color: colors.common.black,
            }}
            component="span"
          >
            {title}
          </Typography>
          <IconButton
            variant="contained"
            component="label"
            sx={{
              borderRadius: 1,
              color: "primary.main",
              fontSize: "2.5rem",
              "&:hover": {
                bgcolor: "primary.dark",
                color: colors.common.white,
              },
            }}
          >
            <CloudUpload fontSize="inherit" />
            <input accept="image/*" type="file" hidden multiple disabled />
          </IconButton>
        </Stack>
        {children}
      </Box>
    </>
  );
};

export default ContentWrapper;
