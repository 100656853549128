import React from 'react';
import {
  Typography,
  Stack,
  Box,
  TableHead,
  Avatar,
  Button,
  IconButton,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { colors } from '@mui/material';
import { useClubs } from '../../../hooks/useClubs';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { config } from 'utils/config';
import { useNavigate } from 'react-router-dom';
import routes from 'data/routes';
import { useAuth } from 'hooks/useAuth';

const Club = () => {
  const { getAllClubs, clubs, removeClub } = useClubs();
  const navigate = useNavigate();
  const { role, profile } = useAuth();

  const isClubAdmin =
    role === 'deptAdmin' && profile?.assignedTo === 'clubAdmin';

  const onRemove = (id) => {
    if (window.confirm('Are you sure to remove')) {
      removeClub(id);
    }
  };

  const toAddClub = () => {
    navigate(isClubAdmin ? routes.DEPT_ADMIN_CLUB_ADD : routes.CLUB_ADD);
  };

  const toEditClub = (id) => {
    navigate(
      isClubAdmin
        ? routes.DEPT_ADMIN_CLUB_EDIT + `/${id}`
        : routes.CLUB_EDIT + `/${id}`
    );
  };

  React.useEffect(() => {
    getAllClubs();
  }, []);

  return (
    <Box sx={{ width: '90%', margin: '0 auto', paddingBottom: 20 }}>
      <Stack justifyContent={'center'} alignItems="center" my={4}>
        <Button onClick={toAddClub} variant="contained">
          Add new Club
        </Button>
        <Typography textAlign="center" mt={2}>
          Current club list-
        </Typography>
      </Stack>
      <Table
        sx={{
          minWidth: 500,
          marginTop: 2,
          width: { xs: '100%', xl: '80%' },
          overflow: 'auto',
          border: '1px solid #e0e0e0',
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '20%' }} align="left">
              Club name
            </TableCell>
            <TableCell sx={{ width: '20%' }} align="left">
              Slogan
            </TableCell>
            <TableCell sx={{ width: '20%' }} align="left">
              History
            </TableCell>
            <TableCell sx={{ width: '20%' }}>Committee</TableCell>
            <TableCell sx={{ width: '20%' }}>Cover</TableCell>
            <TableCell sx={{ width: '20%' }}>Achievement</TableCell>
            <TableCell sx={{ width: '20%' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clubs.map((item, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ width: '20%' }} component="th" scope="row">
                {item.name}{' '}
                <Avatar
                  sx={{ height: 40, width: 40, border: '1px solid #ddd' }}
                  src={config.fileUrl + item.icon}
                />
              </TableCell>
              <TableCell sx={{ width: '20%' }} align="left">
                {item.slogan}
              </TableCell>
              <TableCell align="left">
                <Typography
                  sx={{
                    width: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.history}
                </Typography>
              </TableCell>

              <TableCell sx={{ width: '20%' }} align="left">
                {item.committee
                  ? item.committee?.map((com) => <p>{com.name}</p>)
                  : 'No committee'}
              </TableCell>
              <TableCell sx={{ width: '20%' }} align="left">
                <Avatar
                  sx={{ height: 80, width: 80, border: '1px solid #ddd' }}
                  src={config.fileUrl + item.cover}
                />
              </TableCell>
              <TableCell sx={{ width: '20%' }} align="left">
                {item.achievements?.includes('.pdf') ? (
                  <Button sx={{ marginLeft: 'auto' }}>
                    <a
                      href={config.fileUrl + item.achievements}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      View PDF
                    </a>
                  </Button>
                ) : (
                  <Avatar
                    sx={{ height: 80, width: 80, border: '1px solid #ddd' }}
                    src={config.fileUrl + item.achievements}
                  />
                )}
              </TableCell>
              <TableCell sx={{ width: '20%' }} align="left">
                <IconButton onClick={() => toEditClub(item._id)}>
                  <Edit
                    size="small"
                    sx={{
                      borderRadius: '50%',
                      p: 1,
                      color: colors.common.white,
                      bgcolor: colors.green[500],
                    }}
                  />
                </IconButton>
                <IconButton onClick={() => onRemove(item._id)}>
                  <Delete
                    size="small"
                    sx={{
                      borderRadius: '50%',
                      p: 1,
                      color: colors.common.white,
                      bgcolor: colors.red[500],
                    }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Club;
