import React from 'react';
import {
  Stack,
  Typography,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import { colors } from '@mui/material';
import { useVersion } from 'hooks/useVersions';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

const VersionUpdater = () => {
  const { getVersion, data, updateVersion, updating } = useVersion();

  const [startProgress, setStartProgress] = React.useState(false);

  const [progress, setProgress] = React.useState(0);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const open = () => setDialogOpen(true);
  const close = () => setDialogOpen(false);

  useEffect(() => {
    getVersion();
  }, []);

  const upgradeVersion = () => {
    updateVersion();
    setStartProgress(true);
  };

  React.useEffect(() => {
    if (!startProgress) return;
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [startProgress]);

  useEffect(() => {
    if (progress === 100) {
      close();
      toast.success('Updated');
      setStartProgress(false);
      setProgress(0);
    }
  }, [progress]);

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <Card
      container
      spacing={1}
      sx={{
        maxWidth: '500px',
        borderRadius: '5px',
        margin: '1rem 0',
        padding: '1rem',
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%' }}
        spacing={4}
      >
        <Stack>
          <Typography fontSize={16}>
            Current Version: <b>{data.current}</b>
          </Typography>
          <Typography fontSize={16}>
            Last Updated:
            <b> {format(new Date(data.updatedAt), 'do MMM yy | p')}</b>
          </Typography>
        </Stack>

        <LoadingButton
          loading={updating}
          variant="contained"
          onClick={open}
          sx={{
            height: { xs: '85px', sm: '100px' },
            width: { xs: '85px', sm: '100px' },
            borderRadius: '50%',
            padding: '1rem',
            bgcolor: colors.red[500],
          }}
        >
          Release Now
        </LoadingButton>
      </Stack>

      <Dialog open={dialogOpen} onClose={close}>
        <DialogTitle id="alert-dialog-title" mt={2}>
          Are you sure to update the app version
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            এপ-এর ভার্সন আপডেট এর পর সকল ইউজারকে নতুন করে আপডেটেড এপডেটা ডাউনলোড
            করতে হবে!
          </DialogContentText>
          {startProgress && (
            <Stack alignItems={'center'} mt={5}>
              <CircularProgressWithLabel value={progress} />
            </Stack>
          )}
        </DialogContent>

        <DialogActions sx={{ p: 5 }}>
          <Button variant="outlined" color="info" onClick={close}>
            No, Thanks
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={upgradeVersion}
            autoFocus
          >
            Yes, Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default VersionUpdater;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
