import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useSKillDev = create((set, get) => ({
  isLoading: false,
  isAdding: false,
  skillDevs: [],

  addSkillDev: async (formData, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.post('/admin/skillDev', formData);
      toast.success('item added');
      set(() => ({ isAdding: false }));
      get().getAllSkillDevs();
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  updateSkillDev: async (formData, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.put('/admin/SkillDev', formData);
      toast.success('SkillDevs uploaded');
      set(() => ({ isAdding: false }));
      get().getAllSkillDevs();
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  removeSkillDev: async (id) => {
    try {
      await apiClient.delete(`/admin/SkillDev/${id}`);
      toast.success('SkillDev removed');
      get().getAllSkillDevs();
    } catch (error) {
      console.log({ error });
    }
  },

  getAllSkillDevs: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('/admin/SkillDev');
      set(() => ({ isLoading: false, skillDevs: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
