import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  colors,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
const MultipleImagePreviewCard = ({ removeSelectedImage, selectedImage }) => {
  console.log('selectedImage', selectedImage);
  return (
    <>
      {selectedImage?.map((file, index) => (
        <Grid key={index} item xs={6} sm={4} md={3} xl={2}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              width: '100%',
              margin: '1rem 0',
            }}
          >
            <Clear
              onClick={() => {
                removeSelectedImage(file);
              }}
              size="small"
              sx={{
                bgcolor: colors.red[500],
                color: colors.common.white,
                padding: '0.2rem',
                borderRadius: '50%',
                fontSize: '1.2rem',
                cursor: 'pointer',
              }}
            />
          </div>
          <CardMedia
            key={file}
            component="img"
            image={URL.createObjectURL(file)}
            sx={styles.image}
            alt="Thumb"
          />
        </Grid>
      ))}
    </>
  );
};

export default MultipleImagePreviewCard;

// Just some styles
const styles = {
  container: {},
  preview: {
    marginTop: 2,
    display: 'flex',
    flexDirection: 'row',
    boxShadow: 'none',
    alignItems: 'center',
  },
  image: {
    height: '150px',
    objetFit: 'cover',
  },
  delete: {
    padding: 1,
    width: '100%',
    bgcolor: 'error.main',
  },
};
