import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useCommon = create((set, get) => ({
  isLoading: false,
  isUpdating: false,
  items: [],

  getAllItems: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('/admin/commonItems');
      set(() => ({ isLoading: false, items: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  updateItem: async (data, onDone) => {
    set(() => ({ isUpdating: true }));
    try {
      const response = await apiClient.put('/admin/commonItems', { ...data });
      console.log('After update ', { response });
      set(() => ({ isUpdating: false }));
      const newData = response.data.data;
      if (newData) {
        const newItems = {
          ...get().items,
          [newData.key]: newData,
        };
        set(() => ({ isUpdating: false, items: newItems }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isUpdating: false }));
      onDone();
    }
  },

  removeFile: async (url) => {
    const _url = encodeURIComponent(url);
    console.log(_url);
    try {
      const res = await apiClient.delete(`/admin/commonItems/${_url}`);
      console.log({ res });
    } catch (error) {
      console.log({ error });
    }
  },

  removeFileByUrl: async (url) => {
    const _url = encodeURIComponent(url);
    console.log(_url);
    try {
      const res = await apiClient.delete(`/admin/commonItems/${_url}`);
      console.log({ res });
    } catch (error) {
      console.log({ error });
    }
  },
}));
