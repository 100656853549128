export const formatError = (error) => {
  console.log('on res', error);
  if (!error.response) {
    return 'Something went wrong';
  }
  if (!error.response.status) {
    return 'Network/Server Error';
  }
  if (error.response.data && Array.isArray(error.response.data.errors)) {
    return error.response.data.errors[0].msg;
  }
  if (error.message) {
    return error.message;
  }
};
