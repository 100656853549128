import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useSliders = create((set, get) => ({
  isLoading: false,
  isUploading: false,
  sliders: [],

  uploadSlider: async (file, onDone) => {
    set(() => ({ isUploading: true }));
    try {
      const formData = new FormData();
      formData.append('image', file);
      const res = await apiClient.post('/admin/sliders', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      console.log(res);
      toast.success('Slider uploaded');
      set(() => ({ isUploading: false }));
      get().getAllSliders();
      onDone();
    } catch (error) {
      set(() => ({ isUploading: false }));
    }
  },

  getAllSliders: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('/admin/sliders');
      set(() => ({ isLoading: false, sliders: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  removeSlider: async (url) => {
    const _url = encodeURIComponent(url);
    try {
      await apiClient.delete(`/admin/sliders/${_url}`);
      get().getAllSliders();
    } catch (error) {}
  },
}));
