import React from 'react';
import { Stack, Typography, Button, Box } from '@mui/material';

const PageTitle = ({ color, title }) => {
  return (
    <Stack
      mt={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        variant="h5"
        fontWeight="600"
        sx={{ color: color, textAlign: 'center' }}
        component={'span'}
      >
        {title}
      </Typography>
    </Stack>
  );
};

export default PageTitle;
