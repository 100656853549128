import { Stack, Grid } from '@mui/material';
import MenuItemCard from 'components/cards/MenuItemCard';
import React from 'react';
import {
  MenuBook,
  Campaign,
  Slideshow,
  List,
  Groups,
  FilterList,
  School,
  Favorite,
  Link,
} from '@mui/icons-material';
import routes from 'data/routes';
import VersionUpdater from 'components/cards/VersionUpdater';
import { useAuth } from 'hooks/useAuth';

const DashboardPage = () => {
  const { role } = useAuth();

  const isOfficeAdmin = role === 'officeAdmin';

  const menuItems = [
    {
      id: '2',
      title: 'স্লাইডার ইমেজ',
      route: routes.HOME_SLIDERS,
      icon: <Slideshow />,
    },
    {
      id: '3',
      title: `কেন্দ্রীয় নোটিশ`,
      route: routes.NOTICE,
      icon: <Campaign />,
    },
    {
      id: '33',
      title: `হৃদয়ে বঙ্গবন্ধু`,
      route: routes.BONGOBONDHU,
      icon: <Favorite />,
    },
    {
      id: '5',
      title: 'লাইব্রেরি',
      route: routes.LIBRARY,
      icon: <MenuBook />,
      disabled: isOfficeAdmin,
    },
    {
      id: '4',
      title: 'উচ্চ মাধ্যমিক/ডিগ্রী/অনার্স',
      route: routes.DEPARTMENT_INFO,
      icon: <School />,
    },
    {
      id: '343',
      title: `অনলাইন ভর্তি/ ফর্ম পূরণ`,
      route: routes.ONLINE_ADMISSION,
      icon: <Link />,
    },
    {
      id: '353',
      title: `প্রশাসনিক`,
      route: routes.ADMINISTRATIVE,
      icon: <Link />,
    },
    {
      id: '61',
      title: `প্রকাশনা`,
      route: routes.PUBLICATIONS,
      icon: <List />,
    },

    {
      id: '7',
      title: 'সিটিজেন চার্টার',
      route: routes.CITIZEN_CHARTER,
      icon: <Groups />,
    },
    {
      id: '8',
      title: 'তথ্য অধিকার',
      route: routes.INFO_RIGHT,
      icon: <FilterList />,
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <VersionUpdater />
      </Stack>
      <Grid container spacing={1}>
        {menuItems.map((item, index) => (
          <MenuItemCard
            key={index}
            title={item?.title}
            linkHref={item?.route}
            icon={item?.icon}
            disabled={item.disabled}
          />
        ))}
      </Grid>
    </>
  );
};

export default DashboardPage;
