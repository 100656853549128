import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useAdminList = create((set, get) => ({
  isLoading: false,
  isAdding: false,
  admins: [],

  addAdmin: async (body, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.post('/admin/deptAdmin', body);
      toast.success('Admin added');
      set(() => ({ isAdding: false }));
      get().getAllAdmins();
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  removeAdmin: async (id) => {
    try {
      await apiClient.delete(`/admin/deptAdmin/${id}`);
      toast.success('admin removed');
      get().getAllAdmins();
    } catch (error) {
      console.log({ error });
    }
  },

  getAllAdmins: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('/admin/deptAdmin');
      set(() => ({ isLoading: false, admins: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
