import React from 'react';
import {
  Button,
  Box,
  Typography,
  Modal,
  Grid,
  Avatar,
  Stack,
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { Clear } from '@mui/icons-material';

import icon from './logo.png';

const PdfReader = ({ error, dataset, open, handleClose }) => {
  console.log({ dataset });

  const {
    _id,
    dob,
    electiveSubjects,
    fatherName,
    fathersPhone,
    mothersPhone,
    fourthSubjects,
    group,
    homeDistrict,
    legalGuardian,
    mainSubjects,
    motherName,
    fullName,
    nationality,
    permanentAddress,
    presentAddress,
    quota,
    religion,
    sscBoard,
    sscGpa,
    sscGroup,
    sscRegNo,
    sscRollNo,
    sscYear,
    facebook,
    email,
    studentId,
    phone,
    version,
    photo,
  } = dataset;

  const [printData, setPrintData] = React.useState(dataset);
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState('old boring text');

  const handleAfterPrint = React.useCallback(() => {
    console.log('`onAfterPrint` called'); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log('`onBeforePrint` called'); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log('`onBeforeGetContent` called'); // tslint:disable-line no-console
    setLoading(true);
    setText('Loading new text...');

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText('New, Updated Text!');
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: studentId,
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  React.useEffect(() => {
    setPrintData(dataset);
    if (
      text === 'New, Updated Text!' &&
      typeof onBeforeGetContentResolve.current === 'function'
    ) {
      onBeforeGetContentResolve.current();
    }
    console.log('PrintData:', dataset, error ? 'data' : 'updatedata'); // tslint:disable-line no-console
  }, [onBeforeGetContentResolve.current, text, dataset]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ zIndex: '25', overflowY: 'auto' }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            width: '100%',
            pt: 2,
          }}
        >
          {' '}
          <Clear onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Box>
        <Stack
          direction="row"
          justifyContent="end"
          sx={{ width: '100%', py: 2 }}
        >
          <Button variant="contained" onClick={handlePrint}>
            Print
          </Button>
        </Stack>
        <Box sx={{ overflowX: 'auto' }}>
          <Box ref={componentRef} sx={{ minWidth: '700px', p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar
                    sx={{ height: 70, width: 70 }}
                    alt="Remy Sharp"
                    src={icon}
                  />
                  <Typography
                    sx={{ borderTop: 1, fontSize: '10px', mt: 1 }}
                    variant="caption"
                    display="block"
                  >
                    স্থপতি - ১৮৯৯
                  </Typography>
                  <Typography
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="caption"
                    display="block"
                  >
                    Phone: 081-65988,Fax: 081-71161
                  </Typography>
                  <Typography
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="caption"
                    display="block"
                  >
                    Email: principle@cvgc.edu.bd
                  </Typography>
                  <Typography
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="caption"
                    display="block"
                  >
                    Website: www.cvgc.edu.bd
                  </Typography>
                  <Typography
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="caption"
                    display="block"
                  >
                    www.facebook.com/cvgc.edu.bd1
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Box>
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={3}
                      sx={{ border: 1, textTransform: 'uppercase' }}
                    >
                      <div>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1 }}
                        >
                          Security Code
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1 }}
                        >
                          Id no.
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1 }}
                        >
                          name
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1 }}
                        >
                          Group
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1 }}
                        >
                          version
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1 }}
                        >
                          Date of birth
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        border: 1,
                        textTransform: 'capitalize',
                      }}
                    >
                      <div>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{
                            pl: 1,
                            borderBottom: 1,
                            borderColor: 'black',
                            fontWeight: '700',
                          }}
                        >
                          {_id}
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1, pl: 1, fontWeight: '700' }}
                        >
                          {studentId}
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1, pl: 1, fontWeight: '700' }}
                        >
                          {fullName}
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1, pl: 1, fontWeight: '700' }}
                        >
                          {group}
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1, pl: 1, fontWeight: '700' }}
                        >
                          {version}
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{ borderBottom: 1, pl: 1, fontWeight: '700' }}
                        >
                          {dob}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={3} sx={{ border: 1 }}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{ height: '100%' }}
                      >
                        <img
                          style={{
                            height: '120px',
                            width: '120px',
                            borderRadius: '0px',
                            objectFit: 'contain',
                          }}
                          alt={fullName}
                          src={photo || 'https://via.placeholder.com/120'}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              sx={{ textTransform: 'uppercase', mt: 2 }}
            ></Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Father's Name</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{fatherName}</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Mother's name</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{motherName}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Father's Phone</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{fathersPhone}</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Mother's Phone</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{mothersPhone}</Box>
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>SSC Group</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{sscGroup}</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>SSC GPA[with4thsub]</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{sscGpa}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>SSC Board</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{sscBoard}</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>SSC Roll no</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{sscRollNo}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>SSC Year</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{sscYear}</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>nationality</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{nationality}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>SSC Reg no.</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{sscRegNo}</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Religion</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{religion}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Student Phone</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{phone}</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Home District</Box>
              </Grid>
              <Grid item xs={3} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{permanentAddress}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Student Gmail Id.</Box>
              </Grid>
              <Grid item xs={9} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{email}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Student Facebook Id.</Box>
              </Grid>
              <Grid item xs={9} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{facebook}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Present Address</Box>
              </Grid>
              <Grid item xs={9} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{presentAddress}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Permanent Address</Box>
              </Grid>
              <Grid item xs={9} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{permanentAddress}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Main Subjects</Box>
              </Grid>
              <Grid item xs={9} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{mainSubjects}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Elective Subjects</Box>
              </Grid>
              <Grid item xs={9} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{electiveSubjects}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>4th Subjects</Box>
              </Grid>
              <Grid item xs={9} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{fourthSubjects}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Legal Guardian</Box>
              </Grid>
              <Grid item xs={9} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{legalGuardian}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ textTransform: 'uppercase' }}>
              <Grid item xs={3} sx={{ border: 1, textTransform: 'capitalize' }}>
                <Box sx={{ p: 0.5 }}>Quota</Box>
              </Grid>
              <Grid item xs={9} sx={{ border: 1 }}>
                <Box sx={{ p: 0.5, fontWeight: '700' }}>{quota}</Box>
              </Grid>
            </Grid>
            <Typography
              variant="body2"
              component="div"
              sx={{ fontWeight: '600', p: 1, border: 1 }}
            >
              I,
              {fullName}, do hereby declare that above mentioned information and
              photos are correct. If any information provided by me found false.
              Comilla Victoria Government College reserves the right to cancel
              my admission. I shall be obliged to by the rules and regulations
              of Comilla victoria Government College and pay all the required
              fees.
            </Typography>
            <Stack
              direction="row"
              justifyContent={'space-between'}
              sx={{ pt: 2 }}
            >
              <Box sx={{ mt: 4 }}>---------------------------------</Box>
              <Box sx={{ mt: 4 }}>------------------------------------</Box>
            </Stack>
            <Stack direction="row" justifyContent={'space-between'}>
              <Typography variant="caption">
                শিক্ষার্থীর স্বাক্ষর (বাংলায়) ও তারিখ
              </Typography>
              <Typography variant="caption">
                অভিভাবকের স্বাক্ষর (বাংলায়) ও তারিখ
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent={'start'}
              sx={{ borderTop: 1, pt: 2, mt: 2 }}
            >
              <Stack
                direction="column"
                justifyContent={'center'}
                alignItems="center"
                sx={{ width: '300px' }}
              >
                <Typography sx={{ mt: 3 }} variant="caption" display="block">
                  প্রতিস্বাক্ষর
                </Typography>
                <Box sx={{ mt: 3 }}>---------------------------------</Box>
                <Typography variant="caption" display="block">
                  অধ্যক্ষ
                </Typography>
                <Typography variant="caption" display="block">
                  কুমিল্লা ভিক্টোরিয়া সরকারি কলেজ
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PdfReader;

const style = {
  pt: 5,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -30%)',
  width: '75vw',
  maxWidth: '1000px',
  minHeight: { xs: '300px', md: '400px', lg: '500px' },
  bgcolor: 'background.paper',
  // overflowX: "auto",
  boxShadow: 24,
  p: { xs: 2, md: 3 },
  '@media (max-width: 600px)': {
    transform: 'translate(-50%, -40%)',
  },
};
