import * as React from "react";
import { Button, Box, Typography, Modal, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import styled from "@emotion/styled";

const DatePikerInput = ({
  value,
  handleChange,
  label,
  required,
  name,
  error,
}) => {
  const [currentError, setCurrentError] = React.useState(null);
  const [errorDate, setErrorDate] = React.useState(error);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        id="new-notice-date"
        label={label}
        inputFormat="MM/dd/yyyy"
        value={value}
        error={error}
        onChange={handleChange}
        required={required || false}
        onError={(reason, value) => {
          if (reason || error === true) {
            setCurrentError(reason);
            setErrorDate(true);
          } else {
            setCurrentError(null);
            setErrorDate(false);
          }
        }}
        renderInput={(params) => (
          <StyledInput
            error={errorDate}
            helperText={value ? currentError : "Date Can't be empty"}
            {...params}
            sx={{ width: { xs: "100%" } }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePikerInput;

const StyledInput = styled(TextField)`
  .MuiFormHelperText-root.MuiFormHelperText-root {
    color: #d32f2f;
  }
`;
