import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useNotice = create((set, get) => ({
  isLoading: false,
  isAdding: false,
  notices: [],

  addNotice: async (formData, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.post('/admin/notice', formData);
      toast.success('notice uploaded');
      set(() => ({ isAdding: false }));
      get().getAllNotices();
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  updateNotice: async (formData, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.put('/admin/notice', formData);
      toast.success('notice uploaded');
      set(() => ({ isAdding: false }));
      get().getAllNotices();
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  removeNotice: async (id) => {
    try {
      await apiClient.delete(`/admin/notice/${id}`);
      toast.success('notice removed');
      get().getAllNotices();
    } catch (error) {
      console.log({ error });
    }
  },

  getAllNotices: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('/admin/notice');
      set(() => ({ isLoading: false, notices: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  removeSlider: async (url) => {
    const _url = encodeURIComponent(url);
    try {
      await apiClient.delete(`/admin/notices/${_url}`);
      get().getAllnotices();
    } catch (error) {}
  },
}));
