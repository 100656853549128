import React from 'react';
import { Grid, IconButton, Card, CardMedia } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { config } from 'utils/config';
import { useSliders } from 'hooks/useSliders';

const SliderImage = ({ url }) => {
  const { removeSlider } = useSliders();

  const onRemove = () => {
    if (window.confirm('Are you sure to remove')) {
      const urls = url.split('/');
      const fileName = urls[urls.length - 1];
      removeSlider(fileName);
    }
  };

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Card
        sx={{
          height: {
            xs: '150px',
            sm: '200px',
            md: '250px',
            lg: '300px',
            xl: '350px',
          },
          width: '100%',
          position: 'relative',
        }}
      >
        <CardMedia
          sx={{ objectFit: 'cover', height: '100%', width: '100%' }}
          component="img"
          image={config.fileUrl + url}
          alt="slider image"
        />
        <IconButton
          onClick={onRemove}
          aria-label="delete"
          size="large"
          sx={buttonSx}
        >
          <Delete sx={iconSx} />
        </IconButton>
      </Card>
    </Grid>
  );
};

export default SliderImage;

const buttonSx = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  bgcolor: 'white',
  '&:hover': {
    bgcolor: 'primary.main',
  },
};

const iconSx = {
  color: 'error.main',
  '&:hover': {
    color: 'white',
  },
};
