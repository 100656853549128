import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useHonsNotice = create((set, get) => ({
  isLoading: false,
  isAdding: false,
  notices: [],

  upsertNotice: async (formData, objectKey, onDone) => {
    set(() => ({ isAdding: true }));
    try {
      await apiClient.post('/admin/hons-notices', formData);
      toast.success('Done!!');
      set(() => ({ isAdding: false }));
      get().getAllNotices(objectKey);
      onDone();
    } catch (error) {
      set(() => ({ isAdding: false }));
    }
  },

  removeNotice: async (id, objectKey) => {
    try {
      await apiClient.delete(`/admin/hons-notices/${id}`);
      toast.success('notice removed');
      get().getAllNotices(objectKey);
    } catch (error) {
      console.log({ error });
    }
  },

  getAllNotices: async (key) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get(`/admin/hons-notices/${key}`);
      set(() => ({ isLoading: false, notices: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
