import React, { useState } from 'react';
import { Stack, Box, Modal, Typography } from '@mui/material';
import ImagePreviewCard from 'components/cards/ImagePreviewCard';
import ImageFileInput from 'components/input/ImageFileInput';
import { Clear } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSliders } from 'hooks/useSliders';

const SliderUpload = ({ open, handleClose }) => {
  const { isUploading, uploadSlider } = useSliders();
  const [selectedImage, setSelectedImage] = useState();
  const imageRef = React.useRef();

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage(null);
    // imageRef.current.value = '';
  };

  const onUploadImage = () => {
    uploadSlider(selectedImage, () => {
      setSelectedImage(null);
      handleClose();
    });
  };

  return (
    <Modal
      sx={{ overflowY: 'auto' }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Clear onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Box>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={styles.container}
        >
          {!selectedImage && (
            <>
              <Typography variant="body1" component={'span'}>
                Image size - 1080*600 (Max 2MB)
              </Typography>
              <ImageFileInput imageChange={imageChange} imageRef={imageRef} />
            </>
          )}
          {selectedImage && (
            <>
              <ImagePreviewCard
                selectedImage={selectedImage}
                removeSelectedImage={removeSelectedImage}
              />
              <LoadingButton
                variant="contained"
                sx={{ width: '100%', padding: 1, mt: 2 }}
                loading={isUploading}
                onClick={onUploadImage}
              >
                Upload
              </LoadingButton>
            </>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default SliderUpload;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  maxWidth: '500px',
  minHeight: { xs: '300px', md: '400px', lg: '500px' },
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

// Just some styles
const styles = {
  container: {},
  preview: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  image: { maxWidth: '100%', maxHeight: 320, objetFit: 'cover' },
  delete: {
    padding: 1,
    width: '100%',
    bgcolor: 'error.main',
  },
};
