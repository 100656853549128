import * as React from 'react';
import { Box, Typography, Modal, TextField } from '@mui/material';
import ReactQuillInput from 'components/react-quill/ReactQuill';
import { Clear } from '@mui/icons-material';
import DatePikerInput from 'components/input/DatePikerInput';
import { LoadingButton } from '@mui/lab';
import ImageUploader from 'components/common/ImageUploader';
import { useHonsNotice } from 'hooks/useHonsNotice';

export default function EditHonsNotice({
  open,
  notice,
  handleClose,
  objectKey,
}) {
  const { upsertNotice, isAdding } = useHonsNotice();

  const [dOB, setDOB] = React.useState(new Date());
  const [reactQuill, setReactQuill] = React.useState();

  const [title, setTitle] = React.useState('');

  const [image, setImage] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    upsertNotice(
      {
        _id: notice._id,
        date: dOB,
        image,
        body: reactQuill,
        type: objectKey,
        title,
      },
      objectKey,
      () => handleClose()
    );
  };

  const handleChange = (newDOB) => {
    setDOB(newDOB);
  };

  const handlerReactQuill = (newContent) => {
    setReactQuill(newContent);
  };

  const onUploadImage = (result) => {
    setImage(result?.url);
  };

  React.useEffect(() => {
    if (!notice) return;
    if (notice.body) setReactQuill(notice.body);
    if (notice.date) setDOB(new Date(notice.date));
    if (notice.title) setTitle(notice.title);
    if (notice.image) setImage(notice.image);
  }, [notice]);

  return (
    <Modal open={open} onClose={handleClose} sx={{ overflowY: 'auto' }}>
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Clear onClick={handleClose} />
        </Box>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: 'center' }}
        >
          Update Notice
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Box sx={{ marginTop: '1rem' }}>
            <TextField
              required
              helperText=" "
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              label="Notice Title"
              sx={{ width: { xs: '100%' } }}
            />
          </Box>
          <Box>
            <DatePikerInput
              label={'Date of Notice'}
              value={dOB}
              handleChange={handleChange}
              required={true}
            />
          </Box>
          <Box sx={{ marginTop: '1rem' }}>
            <ReactQuillInput
              value={reactQuill}
              handleChange={handlerReactQuill}
            />
          </Box>
          <ImageUploader imageUrl={image} onUploadImage={onUploadImage} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <LoadingButton loading={isAdding} variant="contained" type="submit">
              Publish Notice
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
const style = {
  position: 'absolute',
  top: '48%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  maxWidth: '500px',
  minHeight: { xs: '300px', md: '400px', lg: '500px' },
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
  '@media (max-width: 600px)': {
    top: '65%',
  },
};
