import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PreviewCommon from 'components/common/PreviewCommon';
import TabPanel from 'components/common/TabPanelCommon';

const BusCar = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_, v) => setValue(v);

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Teacher's Car info" />
            <Tab label="Student's Bus info" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PreviewCommon itemKey={'teachersCar'} showImages />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PreviewCommon itemKey={'studentsBus'} showImages />
        </TabPanel>
      </Box>
    </div>
  );
};

export default BusCar;
