import React from "react";
import { Box } from "@mui/material";
import TabContainer from "components/tabs/Tabs";
import TabItem from "components/tabs/TabItem";
import PageTitle from "components/header/PageTitle";
import OtherContent from "components/admin/department/OtherContent";
import TeacherContent from "components/admin/department/TeacherContent";

const Divitions = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <PageTitle color="primary.main" title="বিজ্ঞান বিভাগ" />
      <Box sx={{ width: "100%" }}>
        <TabContainer
          tabList={Tabdata}
          value={value}
          handleChange={handleChange}
        />
        {data.map((item, index) => (
          <TabItem key={index} value={value} index={index}>
            {item.component}
          </TabItem>
        ))}
      </Box>
    </>
  );
};

export default Divitions;

//array of two data with id and title
const Tabdata = [
  { id: 1, title: "Teacher's List" },
  { id: 2, title: "Others" },
];

const data = [
  { id: 1, title: "Teacher's List", component: <TeacherContent /> },
  { id: 2, title: "Others", component: <OtherContent /> },
];
// Just some styles
const style = {
  p: 0,
};

// Just some styles
const styles = {
  container: {},
  preview: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 320, objetFit: "cover" },
  delete: {
    padding: 1,
    width: "100%",
    bgcolor: "error.main",
  },
};
