import React from 'react';

import { Box, Tabs, Tab } from '@mui/material';

const TabContainer = ({ value, handleChange, tabList }) => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        sx={{
          justifyContent: 'space-between',
        }}
      >
        {tabList.map((item, index) => (
          <Tab
            key={index}
            fontWeight="700"
            label={item.title}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabContainer;
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
